import { useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { BaseType, select as d3Select } from 'd3-selection';
import { drag as d3Drag } from 'd3-drag';

import { chartSettingsSelectors } from '@/store/slices/chartSettings';
import { gatesSelectors } from '@/store/slices/gates';
import { scatterplotsSelectors } from '@/store/slices/scatterplots';
import { usePlotChartIdContext } from '@/contexts/PlotChartIdContext';

import { POLAR_SECTORS_ANGLES, getGateById, getSplitPolarGateSectors, isGateDimensionsReverted } from '@/helpers/gates';
import { isPolarTypeGate } from '@/helpers/typeGuards';
import axisScaleHelper from '@/helpers/axisScaleHelper';

import { getPointsFromChartScale } from './helpers/common';
import { HANDLERS_OFFSET } from './constants';
import { TLineData, drawPolarControls, drawLabels, generateLinesList } from './helpers/polarGate';
import { TSelectionRectElement, TD3DragEvent, EGateDragType, TUseGatesOnPlotPayload } from './types';

export type TDrawPolarPayload = {
  gateId: string;
  center: number[];
  cagesData?: TEntitiesByGates;
  gate?: TGate;
  groupClassName?: string;
  polarGateSubType?: 'polar' | 'split-gate';
};

type TPolarData = {
  group: BaseType;
  x: number;
  y: number;
};

export function usePolarGates({
  chartContainerParameters,
  plotId,
  plotRange,
  isStatic,
  displayType,
  entityLevelGateList,
  isObjectEntityEnabled,
  createGate,
  updateGate,
  handleGateDrag,
  origDataRange,
  isGateMenuHidden,
  specificAxesGroupName,
}: TUseGatesOnPlotPayload) {
  const chartId = usePlotChartIdContext();

  const selectedGate = useSelector(gatesSelectors.selectSelectedGate);
  const gateLabelFormat = useSelector(gatesSelectors.selectGateLabelFormat(chartId));
  const isChangedRef = useRef(false);

  const xAxis = useSelector(scatterplotsSelectors.selectXAxis(specificAxesGroupName));
  const yAxis = useSelector(scatterplotsSelectors.selectYAxis(specificAxesGroupName));
  const { xAxisScaleType, yAxisScaleType } = useSelector(
    chartSettingsSelectors.selectCurrentScalesTypeForAxes(chartId)
  );
  const currentChartType = useSelector(chartSettingsSelectors.selectCurrentChartType(chartId));

  const [currentPolarData, setCurrentPolarData] = useState<Nullable<TPolarData>>(null);

  const onPolarControlDragStart = (control: TSelectionRectElement) => {
    const controlGateIdAttr = control.attr('gate-control-id');
    const gateId = controlGateIdAttr.split('gate-control_')[1];

    handleGateDrag(gateId, EGateDragType.dragStart);
  };

  const onPolarControlDrag = (control: TSelectionRectElement, event: TD3DragEvent) => {
    const controlGateIdAttr = control.attr('gate-control-id');
    const gateId = controlGateIdAttr.split('gate-control_')[1];
    const controlTypeByAngleType = control.attr('control-type-by-offset-angle');

    const group = control.node()?.parentNode;

    if (!group) return;

    const line = d3Select(`#${plotId}`).select(`[gate-id = 'gate_${gateId}']`);

    if (!line.node()) return;

    handleGateDrag(gateId, EGateDragType.drag);

    const currentCenterX = parseFloat(line.attr('x1'));
    const currentCenterY = parseFloat(line.attr('y1'));

    const shapesContainer = d3Select(`#${plotId} .gates-container`).node() as Element;

    if (!shapesContainer || !group) return;

    const shapesContainerParameters = shapesContainer.getBoundingClientRect(); // canvas svg container in which gates are drawn
    const controlXWithOffset = parseFloat(control.attr('x')) + HANDLERS_OFFSET;
    const controlYWithOffset = parseFloat(control.attr('y')) + HANDLERS_OFFSET;

    const newCenterByAngle: Record<string, Record<'x' | 'y', number>> = {
      control_x: { x: controlXWithOffset + event.dx, y: currentCenterY },
      control_y: { x: currentCenterX, y: controlYWithOffset + event.dy },
      control_center: { x: controlXWithOffset + event.dx, y: controlYWithOffset + event.dy },
    };

    const centerX = newCenterByAngle[controlTypeByAngleType].x;
    const centerY = newCenterByAngle[controlTypeByAngleType].y;

    updatePolarGate(group as Element, [centerX, centerY], shapesContainerParameters);
  };

  const onPolarControlDragEnd = useCallback(
    (control: TSelectionRectElement) => {
      const shapesContainer = d3Select(`#${plotId} .gates-container`).node() as Element;
      const shapesContainerParameters = shapesContainer ? shapesContainer.getBoundingClientRect() : null; // canvas svg container in which gates are drawn
      if (!shapesContainerParameters || !chartContainerParameters || !plotRange) return;

      const controlGateIdAttr = control.attr('gate-control-id');
      const gateId = controlGateIdAttr.split('gate-control_')[1];
      const line = d3Select(`#${plotId}`).select(`[gate-id = 'gate_${gateId}']`);

      if (!line.node()) return;
      const currentCenterX = parseFloat(line.attr('x1'));
      const currentCenterY = parseFloat(line.attr('y1'));

      const [updatedCenter] = getPointsFromChartScale({
        polygons: [{ x: currentCenterX, y: currentCenterY }],
        shapesContainerParameters,
        range: plotRange,
      });

      const gate = getGateById(gateId, entityLevelGateList);

      if (!gate || !isPolarTypeGate(gate?.shape)) return;

      handleGateDrag(gateId, EGateDragType.dragEnd, {
        ...gate,
        shape: {
          type: 'polar',
          model: {
            ...gate.shape.model,
            x: updatedCenter.x,
            y: updatedCenter.y,
          },
        },
      });

      const polarSectorsData = gate.gateNodes.map((polarSector) => {
        const { model } = polarSector.shape as TPolarAreaGateShape;

        return {
          id: polarSector.id,
          sectorAngle: model.sectorAngle,
          offsetAngle: model.offsetAngle,
        };
      });

      if (!isChangedRef.current) {
        return;
      }

      const convertedUpdatedCenter = axisScaleHelper.getRealGatePoints({
        xAxisScaleType,
        yAxisScaleType,
        gatePoints: [updatedCenter],
        plotLinearRange: plotRange,
        origDataRange,
      })[0];

      const isDimensionsReverted = isGateDimensionsReverted(gate, xAxis, yAxis);

      updateGate(gate, {
        shape: {
          type: 'polar',
          model: {
            ...gate.shape.model,
            x: isDimensionsReverted ? convertedUpdatedCenter.y : convertedUpdatedCenter.x,
            y: isDimensionsReverted ? convertedUpdatedCenter.x : convertedUpdatedCenter.y,
            sectors: polarSectorsData,
          },
        } as TGateShape,
      });

      isChangedRef.current = false;
    },
    [chartContainerParameters, plotRange, entityLevelGateList]
  );

  const controlDragHandler = d3Drag<SVGRectElement, unknown>()
    .on('start', function handleDragStart() {
      const control = d3Select(this);
      onPolarControlDragStart(control);
    })
    .on('drag', function handleDrag(event) {
      const control = d3Select(this);
      onPolarControlDrag(control, event);
      isChangedRef.current = true;
    })
    .on('end', function handleDragEnd() {
      const control = d3Select(this);
      onPolarControlDragEnd(control);
    });

  const drawPolar = ({
    center,
    gateId,
    cagesData,
    gate,
    groupClassName = 'gate-group',
    polarGateSubType = 'polar',
  }: {
    gateId: string;
    center: number[];
    cagesData?: TEntitiesByGates;
    gate?: TGate;
    groupClassName?: string;
    polarGateSubType?: 'polar' | 'split-gate';
  }) => {
    const shapesContainer = d3Select(`#${plotId} .gates-container`).node() as Element;

    if (!shapesContainer) return;

    const shapesContainerParameters = shapesContainer.getBoundingClientRect(); // canvas svg container in which gates are drawn

    const svg = d3Select(`#${plotId} .gates-container`);
    const group = svg
      .append('g')
      .attr('gate-group-id', `group_${gateId}`)
      .attr('class', groupClassName)
      .attr('gate-type', polarGateSubType);
    const gateIdAttr = `gate_${gateId}`;

    const linesData = generateLinesList(center, shapesContainerParameters, polarGateSubType);

    linesData.forEach((lineData) => {
      group
        .append('line')
        .attr('x1', lineData.x1)
        .attr('x2', lineData.x2)
        .attr('y1', lineData.y1)
        .attr('y2', lineData.y2)
        .attr('class', 'gate-element')
        .attr('gate-id', gateIdAttr);
    });

    const isDimensionsReverted = gate ? isGateDimensionsReverted(gate, xAxis, yAxis) : false;

    if (cagesData && gate) {
      drawLabels({
        parentElement: group,
        gateIdAttr,
        displayType,
        polarSectors: gate.gateNodes,
        cagesData,
        shapesContainerParameters,
        gateLabelFormat,
        polarSubType: polarGateSubType,
        isGateReverted: isDimensionsReverted,
        isGateMenuHidden,
      });
    }

    drawPolarControls({
      linesData,
      parentElement: group,
      isStatic,
      controlDragHandler,
      gateId,
      center,
      polarGateSubType,
    });

    return group;
  };

  const handlePolarMouseDown = useCallback(
    (eventData: [number, number], polarGateSubType: 'polar' | 'split-gate') => {
      if (!chartContainerParameters || currentPolarData) return;

      const polars = d3Select(`#${plotId}`).selectAll(`[gate-type = '${polarGateSubType}']`).nodes() as Nullable<Element>[];

      if (polars.length) return;

      const id = `${Date.now()}`;

      const group = drawPolar({
        center: eventData,
        gateId: id,
        polarGateSubType,
      });

      if (!group) return;

      setCurrentPolarData({
        group: group.node(),
        x: eventData[0],
        y: eventData[1],
      });
    },
    [chartContainerParameters, plotRange]
  );

  const updateControl = (gateId: string, linesData: TLineData[]) => {
    const controls = d3Select(`#${plotId}`)
      .selectAll(`[gate-control-id = 'gate-control_${gateId}']`)
      .nodes() as Nullable<Element>[];

    controls.forEach((controlNode: Nullable<Element>, index: number) => {
      if (controlNode) {
        const lineData = linesData?.[index];

        if (!lineData) return;

        const newX = lineData.x2;
        const newY = lineData.y2;

        d3Select(controlNode)
          .attr('x', newX - HANDLERS_OFFSET)
          .attr('y', newY - HANDLERS_OFFSET);
      }
    });
  };

  const updatePolarGate = (gateGroup: Element, newCenter: [number, number], params: DOMRect) => {
    const group = d3Select(gateGroup);
    const groupId = group.attr('gate-group-id');
    const gateType = group.attr('gate-type') as 'polar' | 'split-gate';
    const linesData = generateLinesList(newCenter, params, gateType);
    const gateId = groupId.replace('group_', '');
    const lines = group.selectAll(`[gate-id = 'gate_${gateId}']`).nodes() as Element[];

    lines.forEach((line, i) => {
      const lineSelection = d3Select(line);
      lineSelection.attr('x1', linesData[i].x1).attr('x2', linesData[i].x2);
      lineSelection.attr('y1', linesData[i].y1).attr('y2', linesData[i].y2);
    });

    const newCenterData =
      gateType === 'polar'
        ? [
            {
              x1: newCenter[0],
              x2: newCenter[0],
              y1: newCenter[1],
              y2: newCenter[1],
            },
          ]
        : [];

    updateControl(gateId, [...linesData, ...newCenterData]);

    setCurrentPolarData((prevState) => {
      if (!prevState) return null;

      return {
        ...prevState,
        x: newCenter[0],
        y: newCenter[1],
      };
    });
  };

  const handlePolarMouseMove = useCallback(
    (eventData: [number, number]) => {
      if (!currentPolarData?.group || !chartContainerParameters) return;
      const shapesContainer = d3Select(`#${plotId} .gates-container`).node() as Element;

      if (!shapesContainer) return;

      const shapesContainerParameters = shapesContainer.getBoundingClientRect(); // canvas svg container in which gates are drawn

      updatePolarGate(currentPolarData.group as Element, eventData, shapesContainerParameters);
    },
    [currentPolarData, chartContainerParameters]
  );

  const handlePolarMouseUp = useCallback(
    (polarGateSubType: 'polar' | 'split-gate') => {
      if (!currentPolarData || !plotRange) return;
      const shapesContainer = d3Select(`#${plotId} .gates-container`).node() as Element;

      if (!shapesContainer) return;
      const shapesContainerParameters = shapesContainer.getBoundingClientRect(); // canvas svg container in which gates are drawn

      if (!shapesContainerParameters || !chartContainerParameters) return null;
      const points = [{ x: currentPolarData.x, y: currentPolarData.y }];
      const polygons = getPointsFromChartScale({
        polygons: points,
        shapesContainerParameters,
        range: plotRange,
      });

      const realPolygons = axisScaleHelper.getRealGatePoints({
        xAxisScaleType,
        yAxisScaleType,
        gatePoints: polygons,
        plotLinearRange: plotRange,
        origDataRange,
      });

      createGate({
        gateName: 'polar',
        newGateModel: {
          type: 'polar',
          model: {
            x: realPolygons[0].x,
            y: realPolygons[0].y,
            sectors: polarGateSubType === 'polar' ? POLAR_SECTORS_ANGLES : getSplitPolarGateSectors(xAxis),
          },
        },
        xDimension: xAxis,
        yDimension: yAxis,
        parentGate: selectedGate,
        isCageLevel: !isObjectEntityEnabled,
        gateTypeByTool: polarGateSubType,
        currentChartType,
      });

      setCurrentPolarData(null);
    },
    [currentPolarData, chartContainerParameters]
  );

  return {
    drawPolar,
    handlePolarMouseDown,
    handlePolarMouseMove,
    handlePolarMouseUp,
  };
}

export default usePolarGates;
