import { select as d3Select } from 'd3-selection';

import { EGateLabelType } from '@/types/gateSettings';

import { TSelectionGroupElement } from '../types';
import { drawControlElement, drawGateLabel, getXPointOnChart, getYPointOnChart, updateMenuPosition } from './common';
import { LABEL_MENU_R, TITLE_GAP_BY_TYPE, TITLE_HEIGHT_BY_TYPE, TITLE_OFFSET_BY_TYPE } from '../constants';

export const MIN_RECT_WIDTH = 10;

export const drawRectangleGate = ({
  gateId,
  gatePolygons,
  label,
  gateContainer,
  displayType,
  isGateMenuHidden,
}: {
  gateId: string;
  gatePolygons: TGatePolygons;
  label: string;
  gateContainer: TSelectionGroupElement;
  displayType: EGateLabelType;
  isGateMenuHidden: boolean;
}) => {
  const xStart = gatePolygons[0].x;
  const xEnd = gatePolygons[2].x;
  const yBottom = gatePolygons[0].y; // the y values are inverted because the coordinates are counted not from the lower left corner, but from the upper
  const yTop = gatePolygons[2].y; // the y values are inverted because the coordinates are counted not from the lower left corner, but from the upper
  const gateIdAttr = gateId ? `gate_${gateId}` : `gate_${Date.now()}`;

  // find current points for cases when dimensions reverted
  const x0 = Math.min(xStart, xEnd);
  const x1 = Math.max(xStart, xEnd);
  const y0 = Math.max(yTop, yBottom);
  const y1 = Math.min(yTop, yBottom);

  drawGateLabel({
    gateContainer,
    bgX: xStart,
    bgY: yTop,
    textX: xStart,
    textY: yTop,
    gateIdAttr,
    label,
    displayType,
    isGateMenuHidden,
  });

  const rect = gateContainer
    .append('rect')
    .attr('class', 'gate-element')
    .attr('gate-id', gateIdAttr)
    .attr('x', x0)
    .attr('y', y1)
    .attr('width', Math.abs(x1 - x0))
    .attr('height', Math.abs(y1 - y0));

  const newControlData = {
    parentElement: gateContainer,
  };

  const defaultAttribute = [{ attr: 'gate-control-id', value: `gate-control_${gateId}` }];

  const bottomLeftPoint = drawControlElement({
    ...newControlData,
    x: xStart,
    y: yBottom,
    attributes: [...defaultAttribute, { attr: 'control-position', value: 'bottom-left' }],
  });

  const topLeftPoint = drawControlElement({
    ...newControlData,
    x: xStart,
    y: yTop,
    attributes: [...defaultAttribute, { attr: 'control-position', value: 'top-left' }],
  });

  const topRightPoint = drawControlElement({
    ...newControlData,
    x: xEnd,
    y: yTop,
    attributes: [...defaultAttribute, { attr: 'control-position', value: 'top-right' }],
  });

  const bottomRightPoint = drawControlElement({
    ...newControlData,
    x: xEnd,
    y: yBottom,
    attributes: [...defaultAttribute, { attr: 'control-position', value: 'bottom-right' }],
  });

  const gateGroup = {
    rectangleElement: rect,
    bottomLeftElement: bottomLeftPoint,
    topLeftElement: topLeftPoint,
    topRightElement: topRightPoint,
    bottomRightElement: bottomRightPoint,
  };

  return gateGroup;
};

export const getRectangleGatePointsFromChartScale = (
  pointsFromLayout: TGatePolygons,
  shapesContainerParameters: DOMRect,
  range: TPopulationRange
): TGatePolygons | void => {
  const xStart = Math.min(pointsFromLayout[0].x, pointsFromLayout[1].x);
  const xEnd = Math.max(pointsFromLayout[0].x, pointsFromLayout[1].x);
  const yBottom = Math.max(pointsFromLayout[0].y, pointsFromLayout[1].y);
  const yTop = Math.min(pointsFromLayout[0].y, pointsFromLayout[1].y);
  const xPayload = {
    point: xStart,
    containerSize: shapesContainerParameters.width,
    chartMinValue: range?.xMin,
    chartMaxValue: range?.xMax,
  };

  const xMinOnChart = getXPointOnChart(xPayload);
  const xMaxOnChart = getXPointOnChart({ ...xPayload, point: xEnd });

  const yPayload = {
    point: yBottom,
    containerSize: shapesContainerParameters.height,
    chartMinValue: range?.yMin,
    chartMaxValue: range?.yMax,
  };
  const yMinOnChart = getYPointOnChart(yPayload);
  const yMaxOnChart = getYPointOnChart({ ...yPayload, point: yTop });

  const polygons = [
    { x: xMinOnChart, y: yMinOnChart },
    { x: xMinOnChart, y: yMaxOnChart },
    { x: xMaxOnChart, y: yMaxOnChart },
    { x: xMaxOnChart, y: yMinOnChart },
  ];

  return polygons;
};

export const updateGateDataByControlType = (
  gatePolygons: TGatePolygons,
  type: string,
  controlElementData: Record<'x' | 'y', number>
) => {
  const polygons = [...gatePolygons];
  let xStart = Math.min(polygons[0].x, polygons[1].x);
  let xEnd = Math.max(polygons[0].x, polygons[1].x);
  let yBottom = Math.max(polygons[0].y, polygons[1].y); // the y values are inverted because the coordinates are counted not from the lower left corner, but from the upper
  let yTop = Math.min(polygons[0].y, polygons[1].y); // the y values are inverted because the coordinates are counted not from the lower left corner, but from the upper
  const isXStartInvalid = xStart + controlElementData.x > xEnd - MIN_RECT_WIDTH;
  const isXEndInvalid = xEnd + controlElementData.x < xStart + MIN_RECT_WIDTH;
  const isYTopInvalid = yTop + controlElementData.y > yBottom - MIN_RECT_WIDTH;
  const isYBottomInvalid = yBottom + controlElementData.y < yTop + MIN_RECT_WIDTH;

  const xStartOffset = isXStartInvalid ? 0 : controlElementData.x;
  const xEndOffset = isXEndInvalid ? 0 : controlElementData.x;
  const yBottomOffset = isYBottomInvalid ? 0 : controlElementData.y;
  const yTopOffset = isYTopInvalid ? 0 : controlElementData.y;

  switch (type) {
    case 'bottom-left':
      xStart += xStartOffset;
      yBottom += yBottomOffset;
      break;
    case 'top-left':
      xStart += xStartOffset;
      yTop += yTopOffset;
      break;
    case 'top-right':
      xEnd += xEndOffset;
      yTop += yTopOffset;
      break;
    case 'bottom-right':
      xEnd += xEndOffset;
      yBottom += yBottomOffset;
      break;
    default:
      break;
  }
  return [
    { x: xStart, y: yBottom },
    { x: xEnd, y: yTop },
  ];
};

export const updateLabelOnRectangleGate = (
  gateId: string,
  xStart: number,
  yTop: number,
  displayType: EGateLabelType,
  plotId: string,
) => {
  const labelBg = d3Select('.gates-container:not(.gates-container_disabled)').select(
    `[label-gate-id = 'label-bg_${gateId}'`
  );
  const labelText = d3Select('.gates-container:not(.gates-container_disabled)').select(
    `[label-gate-id = 'label-text_${gateId}'`
  );

  if (!labelBg?.node() || !labelText?.node()) return;

  const labelBgX = xStart - TITLE_OFFSET_BY_TYPE[displayType] / 2 - TITLE_GAP_BY_TYPE[displayType];
  const labelBgY =
    yTop - TITLE_HEIGHT_BY_TYPE[displayType] - TITLE_OFFSET_BY_TYPE[displayType] - TITLE_GAP_BY_TYPE[displayType];
  const labelTextX = xStart;
  const labelTextY = yTop - TITLE_HEIGHT_BY_TYPE[displayType] / 2 - TITLE_GAP_BY_TYPE[displayType];
  labelBg.attr('x', labelBgX).attr('y', labelBgY);
  labelText.attr('x', labelTextX).attr('y', labelTextY);

  const bgWidth = parseFloat(labelBg.attr('width'));
  const bgHeight = parseFloat(labelBg.attr('height'));

  const menuCX = labelBgX + bgWidth + LABEL_MENU_R;
  const menuCY = labelBgY + bgHeight / 2;

  updateMenuPosition({ gateId, newPosition: [menuCX, menuCY], plotId} );
};
