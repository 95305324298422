import icons from '@/components/common/icons';
import classnames from 'classnames/bind';
import { CSSProperties, FC, ReactElement } from 'react';
import { PREPROCESSING_ASSAYS_BY_ASSAY, addTooltip } from '@/helpers';
import styles from './IconsAssaySet.module.scss';

const cn = classnames.bind(styles);

type TIconsSetProps = {
  assayList: TAssay[];
  color?: 'yellow' | 'gray' | 'light-yellow' | 'transparent' | 'light-yellow-invert';
  className?: string;
  size?: number;
  border?: 'none' | 'default' | 'medium'; // describes border display and width
  hidePreprocessingMark?: boolean;
  withPreprocessingIcon?: boolean;
};
type TIconsTypes = Record<string | number, ReactElement>;

const iconsTypes: TIconsTypes = {
  1: <icons.AssaySurfaceReceptor />,
  2: <icons.AssayCytokineSecretion />,
  3: <icons.AssayCellKilling />,
  4: <icons.AssayMRnaExpression />,
  5: <icons.AssayCiteSeq />,
  6: <icons.AssayGuideSeq />,
  7: <icons.AssayCellProliferation />,
} as const;

const IconsAssaySet: FC<TIconsSetProps> = ({
  assayList = [],
  color,
  className,
  size = 45,
  border = 'none',
  hidePreprocessingMark = false,
  withPreprocessingIcon = false,
}) => {
  const borderWidthOptions = {
    default: 1,
    none: 0,
    medium: 1.5,
  };
  const getFormattedIcons = () =>
    [...assayList]
      .sort((a, b) => (Number(a.properties?.active ?? 0) <= Number(b.properties?.active ?? 0) ? 1 : -1))
      .map((assay) => {
        const isPreprocessing = !hidePreprocessingMark && PREPROCESSING_ASSAYS_BY_ASSAY[assay.id];
        const isActive = assay.properties?.active ?? true;
        return (
          <div
            key={assay.id}
            {...addTooltip(assay.name)}
            className={cn('icons-set__icon', {
              'icons-set__icon_yellow': color === 'yellow' || isActive,
              'icons-set__icon_transparent': color === 'transparent',
              'icons-set__icon_light-yellow-invert': color === 'light-yellow-invert',
              'icons-set__icon_gray': color === 'gray' || !isActive,
            })}
            style={
              {
                '--size': size,
                '--border-width': borderWidthOptions[border],
              } as CSSProperties
            }
          >
            {iconsTypes[assay.id]}
            {color === 'light-yellow-invert' && <div className={cn('icons-set__icon-border')} />}
            {isPreprocessing && (
              <div
                className={cn('icons-set__icon', 'preprocessing', { 'preprocessing_with-icon': withPreprocessingIcon })}
              >
                {withPreprocessingIcon && <icons.PreprocessingIcon />}
              </div>
            )}
          </div>
        );
      });

  return <div className={cn('icons-set', className)}>{getFormattedIcons()}</div>;
};

export default IconsAssaySet;
