import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getDatasetChannelNameByChannelName } from '@/helpers/channels';

import { usePlotChartIdContext } from '@/contexts/PlotChartIdContext';
import { useAppDispatch } from '@/hooks/useAppDispatch';

import { preprocessingSelectors } from '@/store/slices/preprocessing';
import { EStepName } from '@/store/slices/preprocessing/types';
import { scatterplotsSelectors, EAxesGroupName, scatterplotsActions, TAxes } from '@/store/slices/scatterplots';
import { chartSettingsSelectors } from '@/store/slices/chartSettings';

import { EAxesScaleType } from '@/types/charts';

export const DEFAULT_CELL_KILLING_AXES_PRESET = {
  x: 'eccentricity',
  y: 'object_area_px',
};

const getAxesGroupName = (currentStep: EStepName): Nullable<EAxesGroupName> => {
  switch (currentStep) {
    case EStepName.stepCytokineDefineBeads:
    case EStepName.stepCytokineReviewBeads:
    case EStepName.stepCytokineSummary:
      return EAxesGroupName.cytokineSecretion;
    case EStepName.stepCellKillingDefineCells:
    case EStepName.stepCellKillingReviewCells:
      return EAxesGroupName.cellKillingRealCells;
    case EStepName.stepCellKillingDefineCellsTarget:
    case EStepName.stepCellKillingReviewCellsTarget:
    case EStepName.stepCellKillingSummary:
      return EAxesGroupName.cellKillingTargetEffectorCells;
    case EStepName.stepInit:
    default:
      return null;
  }
};

export function usePreprocessingSettingsPreset(_chartDataList: TDatasetDetails[]) {
  const appDispatch = useAppDispatch();
  const chartId = usePlotChartIdContext();


  const isObjectEntityEnabled = useSelector(chartSettingsSelectors.selectIsObjectEntityEnabled(chartId));

  const currentStep = useSelector(preprocessingSelectors.selectCurrentStep);

  const cytokineSecretionAxes = useSelector(
    scatterplotsSelectors.selectAxesByGroup(EAxesGroupName.cytokineSecretion)
  ) as TAxes;
  const cellKillingRealCellsAxes = useSelector(
    scatterplotsSelectors.selectAxesByGroup(EAxesGroupName.cellKillingRealCells)
  ) as TAxes;

  const chartDataList = useSelector(preprocessingSelectors.selectDatasets);
  const defaultChartData = useSelector(preprocessingSelectors.selectCurrentDataset);
  const beadChannel = useSelector(preprocessingSelectors.selectBeadChannel);
  const channelOptionMap = useSelector(preprocessingSelectors.selectChannelOptionMap);
  const axesOptionMap = useSelector(preprocessingSelectors.selectAxesOptionMap);

  const preprocessingChartData = useMemo(
    () =>
      chartDataList.find((el) => !!getDatasetChannelNameByChannelName(el.channelList, beadChannel)) ?? defaultChartData,
    [chartDataList]
  );

  useEffect(() => {
    const newAxesGroupName = getAxesGroupName(currentStep);
    if (newAxesGroupName !== null) {
      appDispatch(scatterplotsActions.setCurrentAxesGroupName(newAxesGroupName));
    }
  }, [currentStep]);

  useEffect(() => {
    if (currentStep !== EStepName.stepInit) {
      return;
    }
    appDispatch(
      scatterplotsActions.clearAxesGroups([
        EAxesGroupName.cytokineSecretion,
        EAxesGroupName.cellKillingRealCells,
        EAxesGroupName.cellKillingTargetEffectorCells,
      ])
    );
  }, [currentStep]);

  useEffect(() => {
    if (currentStep !== EStepName.stepCytokineDefineBeads || cytokineSecretionAxes) {
      return;
    }

    const xAxis = axesOptionMap[`${beadChannel}_mean`]?.[preprocessingChartData.dataset.name] ?? `${beadChannel}_mean`;

    appDispatch(
      scatterplotsActions.setAxes({
        axesGroupName: EAxesGroupName.cytokineSecretion,
        newAxes: {
          x: xAxis,
          y: 'object_area_px',
          isObjectEntityEnabled,
        },
      })
    );
  }, [currentStep, cytokineSecretionAxes, beadChannel, preprocessingChartData, channelOptionMap, axesOptionMap]);

  useEffect(() => {
    if (currentStep !== EStepName.stepCellKillingDefineCells || cellKillingRealCellsAxes) {
      return;
    }
    appDispatch(
      scatterplotsActions.setAxes({
        axesGroupName: EAxesGroupName.cellKillingRealCells,
        newAxes: { ...DEFAULT_CELL_KILLING_AXES_PRESET, isObjectEntityEnabled },
      })
    );
  }, [currentStep, cellKillingRealCellsAxes]);

  return useMemo(
    () => ({
      isReady: true,
      axesScaleTypes: {
        x: EAxesScaleType.log,
        y: EAxesScaleType.log,
      },
    }),
    []
  );
}
