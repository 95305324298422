import { createContext, FC, ReactNode, useContext, useMemo } from 'react';

import { getErrorMessage, showErrorToast } from '@/helpers/errors';

import { appAPI } from '@/store/services/app';

type TProjectListContext = {
  searchQuery: string;
  deleteProject: (projectId: string) => void;
  experimentList: TExperimentFromServer[];
};

const ProjectListContext = createContext<TProjectListContext>({} as TProjectListContext);

export const useProjectListContext = (): TProjectListContext => useContext(ProjectListContext);

type TProjectListContextProviderProps = {
  children: ReactNode;
  searchQuery?: string;
  allProjects?: TProject[];
};

export const ProjectListContextProvider: FC<TProjectListContextProviderProps> = ({
  children,
  allProjects,
  searchQuery = '',
}) => {
  const [deleteProject] = appAPI.useDeleteProjectMutation();
  const { data: experimentsData } = appAPI.useFetchExperimentListQuery({});

  const handleDeleteProject = (projectId: string) => {
    const updatedProjectList = allProjects?.filter((currentProject) => currentProject.id !== projectId) ?? [];
    deleteProject({ projectId, updatedProjectList }).catch((error) => {
      showErrorToast(getErrorMessage(error));
    });
  };

  const projectContextData = useMemo(
    () => ({ deleteProject: handleDeleteProject, searchQuery, experimentList: experimentsData?.list ?? [] }),
    [allProjects, searchQuery, experimentsData?.list]
  );

  return <ProjectListContext.Provider value={projectContextData}>{children}</ProjectListContext.Provider>;
};
