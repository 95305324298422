import { SVGProps } from 'react';

const MinCellSizeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="46" height="45" viewBox="0 0 46 45" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
  <rect x="18.0078" y="17.4199" width="10" height="10" rx="5" fill="#F9EF7D"/>
  <rect x="0.0078125" y="17.4199" width="10" height="10" rx="5" fill="#F9EF7D"/>
  <rect x="7.17969" y="39.8379" width="10" height="10" rx="5" transform="rotate(-60 7.17969 39.8379)" fill="#F9EF7D"/>
  <rect x="15.8359" width="10" height="10" rx="5" transform="rotate(60 15.8359 0)" fill="#F9EF7D"/>
  <rect x="36.0078" y="17.4199" width="10" height="10" rx="5" fill="#F9EF7D"/>
  <rect x="28.5" y="7.8457" width="6" height="6" rx="3" transform="rotate(-60 28.5 7.8457)" fill="#57D4FB"/>
  <rect x="33.8359" y="31.1777" width="10" height="10" rx="5" transform="rotate(60 33.8359 31.1777)" fill="#F9EF7D"/>
  </svg>
);

export default MinCellSizeIcon;
