import { MutableRefObject, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { CELL_CONTOUR_DRAW_SETTINGS, contoursOnCanvas, CURRENT_OBJECT_DRAW_SETTINGS } from '@/helpers/objectsOnCanvas';

import { usePlotChartIdContext } from '@/contexts/PlotChartIdContext';

import { viewerSelectors } from '@/store/slices/viewer';
import { cdnAPI } from '@/store/services/cdnData';
import { experimentSelectors } from '@/store/slices/experiment';
import { chartSettingsSelectors } from '@/store/slices/chartSettings';

import { getContourList } from './helpers';

export default function useDrawObjectsOnImageCrop(
  canvasRef: MutableRefObject<Nullable<HTMLCanvasElement>>,
  scanId?: string
) {
  const chartId = usePlotChartIdContext();
  const isObjectEntityEnabled = useSelector(chartSettingsSelectors.selectIsObjectEntityEnabled(chartId));

  const allowDrawObjectsOnImageCrop = useSelector(viewerSelectors.selectAllowDrawObjectsOnImageCrop);
  const displayCurrentObjOnImageCrop = useSelector(viewerSelectors.selectDisplayCurrentObjOnImageCrop);
  const displayAllObjectsOnImageCrop = useSelector(viewerSelectors.selectDisplayAllObjectsOnImageCrop);
  const currentAppLane = useSelector(experimentSelectors.selectCurrentLane);
  const lane = useSelector(experimentSelectors.selectLane(scanId ?? '', currentAppLane?.id ?? ''));

  const { data: objectList = [] } = cdnAPI.useFetchObjectEntityListQuery(lane ?? currentAppLane);

  const getObject = useCallback(
    (entity: TEntity) => {
      if ('objBboxInCageXtl' in entity) {
        return entity;
      }
      return objectList.find((obj) => obj.globalCageIdMatched === entity.globalCageIdMatched) ?? entity;
    },
    [objectList]
  );

  const getObjectList = useCallback(
    (entity: TEntity) => objectList.filter((obj) => obj.globalCageIdMatched === entity.globalCageIdMatched),
    [objectList]
  );

  const outlineAllObjects = useCallback(
    (entity: TEntity) => {
      if (!canvasRef.current) {
        return;
      }

      const ctx = canvasRef.current.getContext('2d');
      const outlineList = getContourList(getObjectList(entity));
      contoursOnCanvas.draw(ctx, outlineList, { ...CELL_CONTOUR_DRAW_SETTINGS, lineWidth: 1 });
    },
    [canvasRef.current, getObjectList]
  );

  const outlineCurrentObject = useCallback(
    (entity: TEntity) => {
      if (!canvasRef.current) {
        return;
      }

      const ctx = canvasRef.current.getContext('2d');
      const outlineList = getContourList([getObject(entity)]);
      contoursOnCanvas.draw(ctx, outlineList, { ...CURRENT_OBJECT_DRAW_SETTINGS, lineWidth: 2 });
    },
    [canvasRef.current, getObject]
  );

  const drawObjects = useCallback(
    (entity: TEntity, isSelectedCrop: boolean) => {
      if (!allowDrawObjectsOnImageCrop) {
        // objects in primary_analysis.csv don't have "obj_bbox_in_cage_" fields
        return;
      }
      if (displayAllObjectsOnImageCrop) {
        outlineAllObjects(entity);
      }
      if (isObjectEntityEnabled && displayCurrentObjOnImageCrop && isSelectedCrop) {
        outlineCurrentObject(entity);
      }
    },
    [
      allowDrawObjectsOnImageCrop,
      displayAllObjectsOnImageCrop,
      outlineAllObjects,
      displayCurrentObjOnImageCrop,
      outlineCurrentObject,
    ]
  );

  return drawObjects;
}
