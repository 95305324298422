import { CSSProperties, FC, memo, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { MDASH } from '@/helpers';
import { downloadCagesImages } from '@/helpers/cageImages';
import { getErrorMessage, showErrorToast } from '@/helpers/errors';
import { getScatterPlotObjectLevelAxesOptions } from '@/helpers/channels';
import { isHistogramsChartType } from '@/helpers/charts/lineHistogram';

import useWebgl from '@/hooks/useWebgl';
import { lutImageURIList } from '@/hooks/useWebgl/lut';
import useGetEntitiesByLaneList from '@/hooks/entities/useGetEntitiesByLaneList';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { usePlotChartIdContext } from '@/contexts/PlotChartIdContext';

import { navigatorSelectors } from '@/store/slices/navigator';
import { scatterplotsSelectors } from '@/store/slices/scatterplots';
import { chartSettingsSelectors } from '@/store/slices/chartSettings';
import { viewerActions } from '@/store/slices/viewer';
import { experimentSelectors } from '@/store/slices/experiment';

import Modal from '@/components/common/Modal';
import ImageButton from '@/components/common/ImagesScrollArea/components/ImageButton';
import Button from '@/components/common/Button';
import icons from '@/components/common/icons';
import FiltersModal from '@/components/common/ImagesScrollArea/components/ImageModal/FiltersModal';
import LineLoader from '@/components/common/LineLoader';
import { getOption } from '@/components/common/Select/helpers';

import styles from './ImageModal.module.scss';

const cn = classnames.bind(styles);
const DEFAULT_CHANNEL_COLOR = '#eeeeee';
const DISABLED_CHANNEL_COLOR = '#d8d8d8';

type TImageModal = {
  selectedEntity: TEntity;
  setSelectedEntity: (entity: TEntity) => void;
  isModalOpen: boolean;
  closeModal: () => void;
  currentLane: Nullable<TLane>;
  scanList: TScan[];
  chosenGate?: string;
  gateCageList?: TEntity[];
};

const getCageSiblingsOnLanes = (
  lanesWithSameId: (TLane | undefined)[],
  entitiesByLaneList: (TEntity[] | undefined)[],
  currentLane: TLane,
  selectedEntity: TEntity
) =>
  entitiesByLaneList.map((laneEntityList, index) => {
    if (!laneEntityList) {
      return;
    }
    const lane = lanesWithSameId[index];
    if (lane?.dataset.scanId === currentLane.dataset.scanId && lane?.dataset.laneId === currentLane.dataset.laneId) {
      return selectedEntity;
    }
    return laneEntityList.find((laneEntity) => laneEntity.globalCageIdMatched === selectedEntity.globalCageIdMatched);
  });

const ImageModal: FC<TImageModal> = ({
  selectedEntity,
  setSelectedEntity,
  isModalOpen,
  closeModal,
  currentLane,
  scanList,
  chosenGate,
  gateCageList,
}) => {
  const appDispatch = useAppDispatch();
  const chartId = usePlotChartIdContext();

  const { getEntitiesByLaneList } = useGetEntitiesByLaneList();
  const [allSamples, setAllSamples] = useState<(TEntity | undefined)[]>([]);
  const [isSamplesLoading, setIsSamplesLoading] = useState(false);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const experimentName = useSelector(experimentSelectors.selectCurrentExperimentName);
  const renderOptionsFromRedux = useSelector(navigatorSelectors.selectRenderOptions);
  const navigatorOptions = useSelector(navigatorSelectors.selectExperimentOptions);
  const xAxis = useSelector(scatterplotsSelectors.selectXAxis());
  const yAxis = useSelector(scatterplotsSelectors.selectYAxis());
  const isObjectEntityEnabled = useSelector(chartSettingsSelectors.selectIsObjectEntityEnabled(chartId));
  const cageLevelAxesOptionList = useSelector(chartSettingsSelectors.selectCageLevelAxesOptionList(currentLane));
  const currentChartType = useSelector(chartSettingsSelectors.selectCurrentChartType(chartId));

  const objectLevelAxesOptionList = useMemo(
    () => getScatterPlotObjectLevelAxesOptions(currentLane?.channels ?? []),
    [currentLane]
  );

  const scatterPlotAxesOptions = useMemo(
    () => (isObjectEntityEnabled ? objectLevelAxesOptionList : cageLevelAxesOptionList),
    [cageLevelAxesOptionList, objectLevelAxesOptionList, isObjectEntityEnabled]
  );

  const xAxisLabel = useMemo(
    () => getOption(scatterPlotAxesOptions, xAxis)?.label ?? '',
    [scatterPlotAxesOptions, xAxis]
  );
  const yAxisLabel = useMemo(
    () => getOption(scatterPlotAxesOptions, yAxis)?.label ?? '',
    [scatterPlotAxesOptions, yAxis]
  );

  const isCageNavigationEnabled = useMemo(() => !!gateCageList && gateCageList.length > 1, [gateCageList]);
  const cageNavigationDataRef = useRef<{
    gateCageList: Nullable<TEntity[]>;
    selectedEntity: Nullable<TEntity>;
  }>({
    gateCageList: null,
    selectedEntity: null,
  });

  const channelsKeysArr = useMemo(() => Object.keys(renderOptionsFromRedux), [renderOptionsFromRedux]);

  const { renderEntityImage } = useWebgl();

  const channelsSettings: string[][] = useMemo(
    () => scanList.map(({ id: scanId }) => navigatorOptions.channels?.[scanId]?.[currentLane?.id ?? ''] ?? []),
    [scanList, navigatorOptions, currentLane]
  );

  const selectedEntityCoordinatesOnChart = useMemo(() => {
    const x = selectedEntity?.[xAxis]?.toFixed(3) ?? null;
    const y = selectedEntity?.[yAxis]?.toFixed(3) ?? null;

    return { x, y };
  }, [selectedEntity, xAxis, yAxis]);

  const renderSettingsForAllSamples = useMemo(
    () =>
      channelsSettings.map((channelIdList): TRenderOptions => {
        const channelListRenderOptions = channelIdList.map((channelId: string) => renderOptionsFromRedux[channelId]);

        return {
          min: channelListRenderOptions.map(({ range: [min, _] }) => min),
          max: channelListRenderOptions.map(({ range: [_, max] }) => max),
          color: channelListRenderOptions.map(({ color }) => color),
          isActive: channelListRenderOptions.map(({ isActive }) => Number(isActive)),
          isUseLut: channelListRenderOptions.map(({ isUseLut }) => Number(isUseLut)),
          lutTypeList: channelListRenderOptions
            .map(({ lutType }) => lutType)
            .concat(Array.from({ length: 8 - channelListRenderOptions.length }, () => '')),
          isThumbnail: false,
        };
      }),
    [channelsSettings, renderOptionsFromRedux]
  );

  const timelineCompletedTimes = useMemo(() => scanList.map((scan) => scan.time), [scanList.length]);

  const imageColumns = useMemo(() => (allSamples.filter(Boolean).length >= 4 ? 4 : 3), [allSamples.length]);

  const handleCloseModal = () => {
    setIsFiltersOpen(false);
  };

  const handleDownloadImagesButtonClick = () => {
    downloadCagesImages(allSamples, timelineCompletedTimes, selectedEntity?.globalCageIdMatched, experimentName);
  };

  const openFilters = () => {
    setIsFiltersOpen(true);
  };

  const handlePrevButtonClick = () => {
    if (!cageNavigationDataRef.current.gateCageList || !cageNavigationDataRef.current.selectedEntity) {
      return;
    }

    const { cageId, snapshotId } = cageNavigationDataRef.current.selectedEntity;

    const index = cageNavigationDataRef.current.gateCageList.findIndex(
      (gate) => gate.cageId === cageId && gate.snapshotId === snapshotId
    );

    const prevIndex = index === 0 ? cageNavigationDataRef.current.gateCageList.length - 1 : index - 1;

    const prevSample = cageNavigationDataRef.current.gateCageList[prevIndex];

    setSelectedEntity(prevSample);
  };

  const handleNextButtonClick = () => {
    if (!cageNavigationDataRef.current.gateCageList || !cageNavigationDataRef.current.selectedEntity) {
      return;
    }

    const { cageId, snapshotId } = cageNavigationDataRef.current.selectedEntity;

    const index = cageNavigationDataRef.current.gateCageList.findIndex(
      (gate) => gate.cageId === cageId && gate.snapshotId === snapshotId
    );

    const nextIndex = index === cageNavigationDataRef.current.gateCageList.length - 1 ? 0 : index + 1;

    const nextSample = cageNavigationDataRef.current.gateCageList[nextIndex];

    setSelectedEntity(nextSample);
  };

  // sync data to use with document event handlers
  useEffect(() => {
    if (gateCageList) {
      cageNavigationDataRef.current.gateCageList = gateCageList;
    }
    if (selectedEntity) {
      cageNavigationDataRef.current.selectedEntity = selectedEntity;
    }
  }, [gateCageList, selectedEntity]);

  useEffect(() => {
    if (selectedEntity === null || !isModalOpen) {
      setAllSamples([]);
    } else if (currentLane) {
      setIsSamplesLoading(true);
      const lanesWithSameId = scanList.map((scan) => scan.lanes.find((lane) => lane.id === currentLane.id));
      getEntitiesByLaneList(lanesWithSameId)
        .then((entitiesByLaneList) => {
          setAllSamples(getCageSiblingsOnLanes(lanesWithSameId, entitiesByLaneList, currentLane, selectedEntity));
        })
        .catch((error) => {
          showErrorToast(getErrorMessage(error));
        })
        .finally(() => {
          setIsSamplesLoading(false);
        });
    }
  }, [isModalOpen, currentLane, selectedEntity, timelineCompletedTimes]);

  // keyboard support
  const handleKeyInput = (ev: KeyboardEvent) => {
    if (!isCageNavigationEnabled) {
      return;
    }

    const handlers = {
      ArrowLeft: handlePrevButtonClick,
      ArrowRight: handleNextButtonClick,
      ArrowUp: handlePrevButtonClick,
      ArrowDown: handleNextButtonClick,
      default: () => null,
    };

    const code = Object.keys(handlers).includes(ev.code) ? ev.code : 'default';
    handlers[code as keyof typeof handlers]();
  };

  const isNavigatorButtonAllowed = useMemo(
    () => !!selectedEntity?.globalCageIdMatched,
    [selectedEntity?.globalCageIdMatched]
  );

  const handleNavigatorButtonClick = () => {
    appDispatch(viewerActions.setHighlightedCage(selectedEntity));
  };

  useEffect(() => {
    if (!isModalOpen) {
      document.removeEventListener('keyup', handleKeyInput);
    } else {
      document.addEventListener('keyup', handleKeyInput);
    }

    return () => {
      document.removeEventListener('keyup', handleKeyInput);
    };
  }, [isModalOpen]);

  if (!channelsKeysArr) {
    return null;
  }

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick
        isFullScreen
        className={cn('image-modal')}
      >
        <Modal.Header onRequestClose={closeModal} className={cn('image-modal__header')}>
          <div className={cn('image-modal__text-container')}>
            <strong>Cage ID: {selectedEntity?.cageId ?? MDASH}</strong>
            <span>Global cage ID: {selectedEntity?.globalCageIdMatched ?? MDASH}</span>
            <span>Snapshot ID: {selectedEntity?.snapshotId ?? MDASH}</span>
            {selectedEntityCoordinatesOnChart.x && (
              <span>
                {`${xAxisLabel} (X):`} {selectedEntityCoordinatesOnChart.x}
              </span>
            )}
            {selectedEntityCoordinatesOnChart.y && !isHistogramsChartType(currentChartType) && (
              <span>
                {`${yAxisLabel} (Y):`} {selectedEntityCoordinatesOnChart.y}
              </span>
            )}
            {chosenGate && <span>Gate name: {chosenGate}</span>}
          </div>
          {isCageNavigationEnabled && (
            <div className={cn('image-modal__header-controls')}>
              <Button
                color="light"
                onClick={handlePrevButtonClick}
                className={cn('image-modal__header-button', 'image-modal__header-button_prev')}
              />
              <Button
                color="light"
                onClick={handleNextButtonClick}
                className={cn('image-modal__header-button', 'image-modal__header-button_next')}
              />
            </div>
          )}
        </Modal.Header>

        <Modal.Content className={cn('image-modal__content')}>
          <div className={cn('image-modal__functions')}>
            <div className={cn('image-modal__container')}>
              <h2 className={cn('section', 'section_first')}>
                {currentLane?.sampleFriendlyName ?? currentLane?.sampleName}
              </h2>
              {channelsKeysArr.map((channel) => {
                const channelData = renderOptionsFromRedux[channel];
                let channelColor;

                if (!channelData?.isActive) {
                  channelColor = DISABLED_CHANNEL_COLOR;
                } else {
                  channelColor = channelData?.color ?? DEFAULT_CHANNEL_COLOR;
                }

                return (
                  <div key={channel} className={cn('image-modal__channel')}>
                    {!channelData.isUseLut && (
                      <div
                        style={
                          {
                            '--color': channelColor,
                          } as CSSProperties
                        }
                        className={cn('image-modal__color')}
                      />
                    )}
                    {channelData.isUseLut && (
                      <div className={cn('image-modal__lut', { 'image-modal__lut_disabled': !channelData.isActive })}>
                        <img
                          src={lutImageURIList[channelData.lutType]}
                          alt=""
                          className={cn('image-modal__lut-image')}
                        />
                      </div>
                    )}
                    <span
                      className={cn('image-modal__color-text', {
                        'image-modal__color-text_disabled': !channelData.isActive,
                      })}
                    >
                      {channelData.label}
                    </span>
                  </div>
                );
              })}
            </div>

            <div className={cn('image-modal__actions')}>
              <Button
                color="light"
                className={cn('image-modal__button', 'section', 'section_second')}
                onClick={openFilters}
              >
                <icons.CollapseIcon className={cn('image-modal__icon')} />
                <span>Filters</span>
              </Button>

              <Button
                color="light"
                className={cn('image-modal__button', {
                  section: isNavigatorButtonAllowed,
                  section_second: isNavigatorButtonAllowed,
                })}
                onClick={handleDownloadImagesButtonClick}
                disabled={allSamples?.length !== timelineCompletedTimes?.length}
              >
                <icons.DownloadIcon className={cn('image-modal__icon')} />
                <span>Download Images</span>
              </Button>

              {isNavigatorButtonAllowed && (
                <Button
                  color="yellow"
                  className={cn('image-modal__button')}
                  onClick={handleNavigatorButtonClick}
                  tooltip="Highlight the cage in the Navigator"
                >
                  <icons.NavigatorIcon />
                  <span>Navigator</span>
                </Button>
              )}
            </div>

            <LineLoader className={cn('image-modal__line-loader')} isLoading={isSamplesLoading} />
          </div>

          <div
            className={cn('image-modal__images')}
            style={
              {
                '--image-columns': imageColumns,
              } as CSSProperties
            }
          >
            {scanList.map(({ time, timeNode }, index) => {
              const sample = allSamples[index];
              return (
                sample && (
                  <figure className={cn('image-modal__figure')} key={time}>
                    <ImageButton
                      cropEntity={sample}
                      renderEntityImage={renderEntityImage}
                      currentRenderSettings={renderSettingsForAllSamples[index]}
                      renderSettingsList={renderSettingsForAllSamples}
                      className={cn('image-modal__image')}
                      isActionsAllowed
                      scanIndex={index}
                      currentTime={time}
                      cropEntityList={allSamples}
                      currentEntityUuid={selectedEntity?.uuid}
                    />
                    <figcaption className={cn('image-modal__time')}>{timeNode}</figcaption>
                  </figure>
                )
              );
            })}
          </div>
        </Modal.Content>
      </Modal>
      <FiltersModal isOpen={isFiltersOpen} handleCloseModal={handleCloseModal} />
    </>
  );
};

export default memo(ImageModal);
