import { FC, ReactNode, useCallback, useMemo, useState } from 'react';

import { useUserRole } from '@/hooks';
import { useOpenModal } from '@/hooks/useOpenModal';
import { useProjectListContext } from '@/hooks/useProjectListContext';

import icons from '@/components/common/icons';
import { useConfirmationModalContext } from '@/components/common/ConfirmationModalProvider';
import ContextMenuPopoverWrapper from '@/components/common/ContextMenuPopoverWrapper';

import { EAttachDetachAction } from '../constants';

import { ProjectExperimentsAttachDetachModal } from '../ProjectExperimentsAttachDetachModal';

type TProjectOptionsPopover = {
  project: TProject;
  children: ReactNode;
};

const ProjectOptionsPopoverWrapper: FC<TProjectOptionsPopover> = ({ project, children }) => {
  const { id: projectId, name: projectName } = project;
  const confirmationModal = useConfirmationModalContext();
  const { deleteProject, experimentList } = useProjectListContext();

  const { isOpen, setIsOpen, onClose } = useOpenModal();

  const { isProjectDeleteAllowed } = useUserRole();
  const [action, setAction] = useState<Nullable<EAttachDetachAction>>(null);

  const experiments = useMemo(() => {
    if (!experimentList) {
      return [];
    }

    if (action === EAttachDetachAction.Detach) {
      return experimentList.filter((experiment) => project.experimentsIds.includes(experiment.id));
    }

    return experimentList.filter((experiment) => !project.experimentsIds.includes(experiment.id));
  }, [experimentList, action, project.experimentsIds.length]);

  const modalTitle = useMemo(() => {
    if (action === EAttachDetachAction.Detach) {
      return `Detach experiments`;
    }
    return `Attach experiments`;
  }, [action]);

  const handleDeleteProjectModal = async () => {
    const result = await confirmationModal.onOpen({
      confirmationText: `Are you sure you want to delete project ${projectName}?`,
      approveButtonText: 'Delete',
    });

    if (result) {
      deleteProject(projectId);
    }
  };

  const openAttachmentModal = useCallback(
    (attachType: EAttachDetachAction) => () => {
      setAction(attachType);
      setIsOpen(true);
    },
    []
  );

  const closeModal = useCallback(() => {
    onClose();
  }, [onClose]);

  const options = [
    {
      id: 'attach-experiments',
      title: 'Attach experiments',
      icon: <icons.AttachIcon />,
      onClick: openAttachmentModal(EAttachDetachAction.Attach),
    },
    {
      id: 'detach-experiments',
      title: 'Detach experiments',
      icon: <icons.DetachIcon />,
      onClick: openAttachmentModal(EAttachDetachAction.Detach),
    },
    {
      id: 'delete-project',
      title: 'Delete project',
      icon: <icons.DeleteIcon />,
      onClick: handleDeleteProjectModal,
    },
  ];

  if (!isProjectDeleteAllowed) {
    return null;
  }

  return (
    <ContextMenuPopoverWrapper activeElementId={projectId} options={options}>
      <ProjectExperimentsAttachDetachModal
        isOpen={isOpen}
        onClose={closeModal}
        projectId={project.id}
        experimentList={experiments}
        action={action}
        title={modalTitle}
      />
      {children}
    </ContextMenuPopoverWrapper>
  );
};

export default ProjectOptionsPopoverWrapper;
