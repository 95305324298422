import { useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';

import { usePlotChartIdContext } from '@/contexts/PlotChartIdContext';

import { getErrorMessage } from '@/helpers/errors';
import { findLaneInScanList } from '@/helpers/scans';

import { useAppDispatch } from '@/hooks/useAppDispatch';

import { chartSettingsActions, chartSettingsSelectors, EEntityObjectType } from '@/store/slices/chartSettings';
import { cdnAPI } from '@/store/services/cdnData';
import { chartDataActions } from '@/store/slices/chartData';
import { experimentSelectors } from '@/store/slices/experiment';

export const filterEntityListByObjectType = (entityList: TEntity[], objectType: EEntityObjectType) => {
  if (objectType === EEntityObjectType.isCell) {
    return entityList.filter((entity: TEntity) => !!entity.is_cell);
  }
  if (objectType === EEntityObjectType.isBead) {
    return entityList.filter((entity: TEntity) => !!entity.is_bead);
  }
  return entityList;
};

// TODO: MATRIX_SINGLE_CHART need change this hook logic, which will calculate each of matrix plot
const useChartEntityList = (datasetList: TDataset[], shouldForceCageLevel = false) => {
  const appDispatch = useAppDispatch();
  const chartId = usePlotChartIdContext();


  const scanList = useSelector(experimentSelectors.selectCurrentScanList);
  const isCageLvlForced = useSelector(chartSettingsSelectors.selectIsCageLvlForced(chartId));

  const [fetchObjectEntityList] = cdnAPI.useLazyFetchObjectEntityListQuery();
  const [fetchCageEntityList] = cdnAPI.useLazyFetchCageEntityListQuery();

  const isObjectEntityEnabled = useSelector(chartSettingsSelectors.selectIsObjectEntityEnabled(chartId));
  const isObjectEntityEnabledForced = useRef<boolean>(false);
  const isObjectEntityLevel = useMemo(() => {
    if ((shouldForceCageLevel && !isObjectEntityEnabledForced.current) || isCageLvlForced) {
      return false;
    }
    return isObjectEntityEnabled;
  }, [shouldForceCageLevel, isObjectEntityEnabled, isCageLvlForced]);
  const lastRequestedIsObjectEntityLevelRef = useRef(isObjectEntityLevel);

  const objectType = useSelector(chartSettingsSelectors.selectObjectType(chartId));

  const uniqueDatasetPathList = useMemo(() => [...new Set(datasetList.map((dataset) => dataset.path))], [datasetList]);

  const uniqueLaneList = useMemo(() => {
    const chartLaneList = datasetList
      .map((dataset) => findLaneInScanList(scanList, dataset.scanId, dataset.laneId))
      .filter((lane) => !!lane) as TLane[];
    return uniqueDatasetPathList.map((datasetPath) =>
      chartLaneList.find((lane) => lane.dataset.path === datasetPath)
    ) as TLane[];
  }, [datasetList, scanList, uniqueDatasetPathList]);

  useEffect(() => {
    uniqueLaneList.forEach((lane) => {
      const fetchEntityList = isObjectEntityLevel ? fetchObjectEntityList : fetchCageEntityList;
      lastRequestedIsObjectEntityLevelRef.current = isObjectEntityLevel;
      appDispatch(chartDataActions.setLoadingEntityListData({ datasetPath: lane.dataset.path }));
      fetchEntityList(lane, true)
        .unwrap()
        .then((entityList) => {
          if (lastRequestedIsObjectEntityLevelRef.current !== isObjectEntityLevel) {
            return;
          }
          const filteredEntityList = isObjectEntityLevel
            ? filterEntityListByObjectType(entityList, objectType)
            : entityList;
          appDispatch(
            chartDataActions.updateEntityListData({ datasetPath: lane.dataset.path, data: filteredEntityList })
          );
        })
        .catch((error) => {
          if (lastRequestedIsObjectEntityLevelRef.current !== isObjectEntityLevel) {
            return;
          }
          appDispatch(
            chartDataActions.updateEntityListData({ datasetPath: lane.dataset.path, error: getErrorMessage(error) })
          );
        });
    });
  }, [uniqueLaneList, isObjectEntityLevel, objectType]);

  // switch to a cell if the dataset has passed one of the preprocessing and is displayed alone on the page
  useEffect(() => {
    if (shouldForceCageLevel && !isObjectEntityEnabledForced.current) {
      isObjectEntityEnabledForced.current = true;

      appDispatch(chartSettingsActions.setIsObjectEntityEnabled(false));
    }
  }, [shouldForceCageLevel]);
};

export default useChartEntityList;
