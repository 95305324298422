import { ChangeEvent, FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames/bind';
import { toast } from 'react-toastify';

import { inviteUserSchema } from '@/validationSchemas';
import { themeOptions } from '@/types/theme';
import { TMemberRole, MemberRole } from '@/helpers';
import { PhoneNumberInputRegex } from '@/helpers/validator';
import { getErrorMessage, showErrorToast } from '@/helpers/errors';

import { authAPI } from '@/store/services/auth';

import Modal from '@/components/common/Modal';
import TextInput from '@/components/common/TextInput';
import Button from '@/components/common/Button';
import Select from '@/components/common/Select';
import { useFetchNextToken } from '@/hooks';

import styles from './AdminModals.module.scss';

import type { TAdminModalProps, TErrorsMapGeneric } from './types';

const cn = classnames.bind(styles);

const validationFields = {
  email: 'email',
  name: 'name',
  phoneNumber: 'phoneNumber',
} as const;

type TErrorsMap = TErrorsMapGeneric<typeof validationFields>;

const InviteUserModal: FC<TAdminModalProps> = ({ closeModal, isModalOpen }) => {
  const [inviteUser, { isLoading }] = authAPI.useInviteUserMutation();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [role, setRole] = useState<TMemberRole>(MemberRole.viewer);
  const [errors, setErrors] = useState<TErrorsMap>({});
  const [selectedTeam, setSelectedTeam] = useState<Nullable<TTeam>>(null);
  const [teamName, setTeamName] = useState('');

  const isInviteInProgress = useMemo(() => isLoading, [isLoading]);

  const { list: teamList, isListLoading: isTeamListLoading } = useFetchNextToken<TTeam>({
    useFetch: authAPI.useFetchTeamListQuery,
    select: authAPI.endpoints.fetchTeamList.select,
  });

  const teamOptions = useMemo<TOption[]>(
    () =>
      teamList.map((team) => ({
        label: team.name,
        value: team.id,
      })),
    [teamList]
  );

  const roleOptions = useMemo<TOption[]>(() => {
    const result: TOption[] = [];
    result.push({
      label: 'Admin',
      value: MemberRole.admin,
    });
    result.push({
      label: 'Maintainer',
      value: MemberRole.maintainer,
    });
    result.push({
      label: 'Viewer',
      value: MemberRole.viewer,
    });
    return result;
  }, []);

  const handleChangeName = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  }, []);

  const handleChangeEmail = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value.trim());
    setErrors((prev) => {
      delete prev.email;
      return prev;
    });
  }, []);

  const handleChangePhoneNumber = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (!PhoneNumberInputRegex?.test(event.target.value)) {
      return;
    }
    setErrors((prev) => {
      delete prev.phoneNumber;
      return prev;
    });
    setPhoneNumber(event.target.value);
  }, []);

  const handleChangeRole = useCallback((newRole: TMemberRole) => {
    setRole(newRole);
  }, []);

  const handleSelectTeam = useCallback(
    (option: TBasicOption) => {
      const userToSelect = teamList.find((team) => team.id === option.value);
      if (!userToSelect) {
        setSelectedTeam(null);
        setTeamName('');
        return;
      }
      setSelectedTeam(userToSelect);
      setTeamName(userToSelect.name);
    },
    [teamList]
  );

  const handleFormSubmit = useCallback(() => {
    const validate = inviteUserSchema.safeParse({
      email,
      phoneNumber: phoneNumber ?? undefined,
    });
    if (!validate.success) {
      setErrors(
        validate.error.issues.reduce((acc: TErrorsMap, { message, path }) => {
          acc[path[0].toString() as keyof typeof validationFields] = message;
          return acc;
        }, {})
      );
      return;
    }
    inviteUser({
      email,
      name,
      phoneNumber,
      role,
      teamId: selectedTeam?.id ?? '',
    })
      .unwrap()
      .then(() => {
        closeModal();
      })
      .catch((error) => {
        showErrorToast(getErrorMessage(error));
      });
  }, [email, name, phoneNumber, role, selectedTeam]);

  useEffect(() => {
    setName('');
    setEmail('');
    setPhoneNumber('');
    setErrors({});
    setSelectedTeam(null);
    setTeamName('');
  }, [isModalOpen]);

  useEffect(() => {
    Object.values(errors).forEach((message) => {
      toast.error(message);
    });
  }, [errors]);

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      shouldCloseOnOverlayClick
      className={cn('modal')}
      onFormSubmit={handleFormSubmit}
    >
      <Modal.Header onRequestClose={closeModal}>
        <h2>Invite user</h2>
      </Modal.Header>
      <Modal.Content>
        <div className={cn('modal__row')}>
          <TextInput
            className={cn('modal__input', 'modal__input_error')}
            value={email}
            label={
              <>
                <span>Email</span>
                <span className={cn('required')}>*</span>
              </>
            }
            labelClassName={cn('modal__input-label', { 'modal__input-label_error': errors.email })}
            onChange={handleChangeEmail}
            isBgLight
            id="invite-user__email-input"
            errorMessage={errors.email}
          />
        </div>
        <div className={cn('modal__row')}>
          <TextInput
            className={cn('modal__input')}
            value={name}
            label={<span>Full name</span>}
            labelClassName={cn('modal__input-label')}
            onChange={handleChangeName}
            isBgLight
            id="invite-user__name-input"
            errorMessage={errors.name}
          />
        </div>
        <div className={cn('modal__row')}>
          <TextInput
            className={cn('modal__input')}
            value={phoneNumber}
            label="Phone number"
            labelClassName={cn('modal__input-label', { 'modal__input-label_error': errors.phoneNumber })}
            onChange={handleChangePhoneNumber}
            isBgLight
            id="invite-user__phone-number-input"
            errorMessage={errors.phoneNumber}
          />
        </div>
        <div className={cn('modal__row')}>
          <span className={cn('modal__input-label', 'select__label')}>Role</span>
          <Select
            className={cn('modal__select', 'select__control-container')}
            value={role}
            options={roleOptions}
            theme={themeOptions.light}
            onChange={handleChangeRole}
            id="invite-user__role-select"
          />
        </div>
        <div className={cn('modal__row')}>
          <span className={cn('modal__input-label', 'select__label')}>Select team</span>
          <Select
            value={selectedTeam?.name}
            options={teamOptions}
            inputValue={teamName}
            className={cn('modal__select', 'select__control-container')}
            theme={themeOptions.light}
            onChange={handleSelectTeam}
            isEditable
            isClearable
            setInputValue={setTeamName}
            isLoading={isTeamListLoading}
          />
        </div>
      </Modal.Content>
      <Modal.Footer className={cn('modal__footer')}>
        <Button
          type="button"
          id="create-project__cancel"
          onClick={closeModal}
          color="white"
          className={cn('modal__button')}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          id="create-project__begin"
          color="yellow"
          className={cn('modal__button')}
          isLoading={isInviteInProgress}
        >
          Invite
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default memo(InviteUserModal);
