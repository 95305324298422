import Button from '@/components/common/Button';
import Modal from '@/components/common/Modal';
import { FC, useMemo, useState } from 'react';
import { appAPI } from '@/store/services/app';
import classnames from 'classnames/bind';
import Select from '@/components/common/Select';
import { themeOptions } from '@/types/theme';
import { EAttachDetachAction } from '../constants';

import styles from './ProjectExperimentsAttachDetachModal.module.scss';

const cn = classnames.bind(styles);

type TProjectExperimentsAttachDetachModalProps = {
  isOpen: boolean;
  onClose: () => void;
  experimentList: TExperimentFromServer[];
  projectId: string;
  action: Nullable<EAttachDetachAction>;
  title: string;
};

export const ProjectExperimentsAttachDetachModal: FC<TProjectExperimentsAttachDetachModalProps> = ({
  isOpen,
  onClose,
  experimentList,
  projectId,
  action,
  title,
}) => {
  const [experimentIds, setExperimentIds] = useState<string[]>([]);

  const [attachExperiment, { isLoading: isAttachLoading }] = appAPI.useAttachExperimentToProjectMutation();

  const [detachExperiment, { isLoading: isDetachLoading }] = appAPI.useDetachExperimentToProjectMutation();

  const experimentListOptions = useMemo<TOption[]>(
    () => experimentList.map((el) => ({ label: el.name, value: el.id })),
    [experimentList]
  );

  const handleSubmitModal = () => {
    if (action === EAttachDetachAction.Attach) {
      attachExperiment({ id: projectId, ids: experimentIds }).finally(() => {
        setExperimentIds([]);
        onClose();
      });
    } else {
      detachExperiment({ id: projectId, ids: experimentIds }).then(() => {
        setExperimentIds([]);
        onClose();
      });
    }
  };

  const handleSelectChange = (newValues: string[]) => {
    setExperimentIds(newValues);
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} shouldCloseOnOverlayClick className={cn('attachment-modal')}>
      <Modal.Header onRequestClose={onClose}>
        <h2>{title}</h2>
      </Modal.Header>
      <Modal.Content>
        <Select
          theme={themeOptions.light}
          value={experimentIds}
          defaultValue={experimentIds}
          onChange={handleSelectChange}
          multiple
          className={cn('attachment-modal__selector')}
          containerClassName={cn('attachment-modal__select-container')}
          menuClassName={cn('attachment-modal__select-menu')}
          menuListClassName={cn('attachment-modal__select-menu-list')}
          options={experimentListOptions}
        />
      </Modal.Content>
      <Modal.Footer className={cn('attachment-modal__footer')}>
        <Button onClick={onClose} color="gray" className={cn('attachment-modal__button')}>
          Cancel
        </Button>
        <Button
          onClick={handleSubmitModal}
          color="yellow"
          className={cn('attachment-modal__button')}
          isLoading={isAttachLoading || isDetachLoading}
        >
          Apply
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
