import { createContext, FC, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '@/hooks/useAppDispatch';

import { experimentSelectors } from '@/store/slices/experiment';
import { viewerActions, viewerSelectors } from '@/store/slices/viewer';

import NavigatorModal from '@/pages/Experiment/components/Navigator';

import CageInspectorModal from './CageInspectorModal';

const ExperimentModalsContext = createContext<TExperimentModalsContext>({} as TExperimentModalsContext);

export const useExperimentModalsContext = (): TExperimentModalsContext => useContext(ExperimentModalsContext);

type TExperimentModalsProviderProps = {
  children: ReactNode;
};

export const ExperimentModalsProvider: FC<TExperimentModalsProviderProps> = ({ children }) => {
  const appDispatch = useAppDispatch();

  const currentAppLane = useSelector(experimentSelectors.selectCurrentLane);
  const currentAppDataset = useSelector(experimentSelectors.selectCurrentDataset);
  const highlightedCage = useSelector(viewerSelectors.selectHighlightedCage);

  const [currentDataset, setCurrentDataset] = useState<Nullable<TDataset>>(null);
  const [isNavigatorOpen, setIsNavigatorOpen] = useState(false);

  const [cageInspectorState, setCageInspectorState] = useState<TCageInspectorState>({ isOpen: false });
  const [cageInspectorFromNavigatorState, setCageInspectorFromNavigatorState] = useState<TCageInspectorState>({
    isOpen: false,
  });

  const openNavigatorModal = useCallback(
    (props?: TNavigatorModalProps) => {
      setCurrentDataset(props?.dataset ?? currentAppDataset);
      setIsNavigatorOpen(true);
    },
    [currentAppDataset]
  );

  const closeNavigatorModal = useCallback(() => {
    appDispatch(viewerActions.setHighlightedCage(null));
    setCurrentDataset(null);
    setIsNavigatorOpen(false);
  }, []);

  const openCageInspectorModal = useCallback(
    ({ entity, lane, gateName, entityList }: TCageInspectorModalProps) => {
      setCageInspectorState({
        entity,
        lane: lane ?? currentAppLane,
        gateName,
        entityList,
        isOpen: true,
      });
    },
    [currentAppLane]
  );

  const openCageInspectorFromNavigatorModal = useCallback(
    ({ entity, lane, gateName, entityList }: TCageInspectorModalProps) => {
      setCageInspectorFromNavigatorState({
        entity,
        lane: lane ?? currentAppLane,
        gateName,
        entityList,
        isOpen: true,
      });
    },
    [currentAppLane]
  );

  useEffect(() => {
    if (highlightedCage) {
      if (isNavigatorOpen) {
        setCageInspectorFromNavigatorState({ isOpen: false });
      } else {
        openNavigatorModal();
      }
    }
  }, [highlightedCage, openNavigatorModal]);

  const modalContext: TExperimentModalsContext = useMemo(
    () => ({
      openNavigator: openNavigatorModal,
      isNavigatorOpen,
      openCageInspector: openCageInspectorModal,
      isCageInspectorOpen: cageInspectorState.isOpen,
    }),
    [openNavigatorModal, isNavigatorOpen, openCageInspectorModal, cageInspectorState.isOpen]
  );

  return (
    <ExperimentModalsContext.Provider value={modalContext}>
      {children}
      {currentDataset && (
        <NavigatorModal
          isOpen={isNavigatorOpen}
          closeModal={closeNavigatorModal}
          dataset={currentDataset}
          openCageInspector={openCageInspectorFromNavigatorModal}
        />
      )}
      <CageInspectorModal cageInspectorState={cageInspectorState} setCageInspectorState={setCageInspectorState} />
      <CageInspectorModal
        cageInspectorState={cageInspectorFromNavigatorState}
        setCageInspectorState={setCageInspectorFromNavigatorState}
      />
    </ExperimentModalsContext.Provider>
  );
};
