import type { RootState } from '@/store';
import { createSelector } from '@reduxjs/toolkit';

import { getIsPreprocessingAssay, isDatasetDetailsNeedPreprocessing } from '@/helpers/preprocessing';
import { PREPROCESSING_ASSAYS_BY_ASSAY } from '@/helpers';

import { selectCurrentExperimentId } from '@/store/slices/experiment/selectors';

const selectChannelDetailsLists = (state: RootState) => state.datasets.channelDetailsLists;
const selectDatasetDetailsLists = (state: RootState) => state.datasets.datasetDetailsLists;
const selectLaneDetailsLists = (state: RootState) => state.datasets.laneDetailsLists;

export const selectIsDatasetsView = (state: RootState) => state.datasets.isDatasetsView;

export const selectIncludedChannels = (state: RootState) => state.datasets.includedChannels;

export const selectIncludedTimePoints = (state: RootState) => state.datasets.includedTimePoints;

export const selectFilterAssayId = (state: RootState) => state.datasets.filterAssayId;

export const selectEditMode = (state: RootState) => state.datasets.isEditMode;

export const selectIsPreprocessingView = (state: RootState) => state.datasets.isPreprocessingView;

// https://redux.js.org/usage/deriving-data-selectors#writing-memoized-selectors-with-reselect

export const selectDatasetDetailsList = (experimentId: string, laneId: Nullable<string> = null) =>
  createSelector([selectFilterAssayId, selectDatasetDetailsLists], (filterAssayId, datasetDetailsLists) => {
    let datasetDetailsList = datasetDetailsLists[experimentId] ?? [];
    if (laneId) {
      datasetDetailsList = datasetDetailsList.filter((datasetDetails) => datasetDetails.dataset.laneId === laneId);
    }

    if (filterAssayId) {
      return datasetDetailsList.filter(
        (datasetDetails) => !!datasetDetails.assayList.find((assay) => assay.id === filterAssayId)
      );
    }
    return datasetDetailsList;
  });

export const selectChannelDetailsList = (experimentId: string, laneId: Nullable<string> = null) =>
  createSelector(
    [selectFilterAssayId, selectChannelDetailsLists, selectIsPreprocessingView],
    (filterAssayId, channelDetailsLists, isPreprocessingView) => {
      let channelDetailsList = channelDetailsLists[experimentId] ?? [];
      if (laneId) {
        channelDetailsList = channelDetailsList.filter((channelDetails) => channelDetails.dataset.laneId === laneId);
      }
      if (filterAssayId && !isPreprocessingView) {
        return channelDetailsList.filter((channelDetails) => filterAssayId === channelDetails.assay?.id);
      }
      return channelDetailsList;
    }
  );

export const selectLaneDetailsList = createSelector(
  [selectCurrentExperimentId, selectLaneDetailsLists],
  (currentExperimentId, laneDetailsLists) => laneDetailsLists[currentExperimentId] || []
);

export const selectHasOpenLaneDetails = (experimentId: string) =>
  createSelector([selectLaneDetailsLists], (laneDetailsLists) => {
    const laneDetailsList = laneDetailsLists[experimentId] || [];
    return !!laneDetailsList.find((laneDetails) => laneDetails.isOpen);
  });

const getDetailsList = (assaysData: Map<string, any>, detailsList: TDatasetDetails[]) => {
  detailsList.forEach((detail) => {
    const assayId = detail.assay?.id ?? '';
    if (!assayId) {
      return;
    }

    if (!assaysData.has(assayId)) {
      assaysData.set(
        assayId,
        new Map<string, Set<string>>([
          ['timePoints', new Set<string>()],
          ['channels', new Set<string>()],
          ['samples', new Set<string>()],
          ['markers', new Set<string>()],
        ])
      );
    }

    assaysData.get(assayId).get('timePoints').add(detail.timePoint);
    assaysData.get(assayId).get('channels').add(detail.channelName);
    assaysData.get(assayId).get('samples').add(detail.laneId);

    detail.markerList?.forEach(({ marker }) => {
      assaysData.get(assayId).get('markers').add(marker);
    });

    assaysData.get(assayId).set('isPreprocessing', !!PREPROCESSING_ASSAYS_BY_ASSAY[assayId]);
  });
};

const transformAssaysData = (assaysData: Map<string, any>) => {
  assaysData.forEach((assayData, key) => {
    const obj = Object.fromEntries(assayData);
    obj.timePoints = [...obj.timePoints].sort((a, b) => {
      if (a === b) return 0;
      return a > b ? 1 : -1;
    });
    obj.channels = [...obj.channels].filter(Boolean);
    obj.samples = obj.samples.size;
    obj.markers = [...obj.markers].filter(Boolean);
    assaysData.set(key, obj);
  });
};

export const selectAssayProperties = (experimentId: string) =>
  createSelector([selectChannelDetailsLists, selectDatasetDetailsLists], (channelDetailsLists, datasetDetailsLists) => {
    const assaysData = new Map<string, any>();

    const datasetDetailsList = datasetDetailsLists[experimentId] ?? [];
    const channelDetailsList = channelDetailsLists[experimentId] ?? [];

    getDetailsList(assaysData, datasetDetailsList);
    getDetailsList(assaysData, channelDetailsList);
    transformAssaysData(assaysData);

    return assaysData;
  });

export const selectIsPreprocessingFilteredAssay = (experimentId: string) =>
  createSelector([selectFilterAssayId, selectChannelDetailsLists], (filterAssayId, channelDetailsLists) => {
    const list = channelDetailsLists[experimentId] || [];

    return getIsPreprocessingAssay(list, filterAssayId);
  });

export const selectPreprocessingDatasets = (experimentId: string) =>
  createSelector([selectChannelDetailsLists], (channelDetailsLists) => {
    const datasetDetailsList = channelDetailsLists[experimentId] ?? [];
    return datasetDetailsList.filter((datasetDetails) => isDatasetDetailsNeedPreprocessing(datasetDetails));
  });
