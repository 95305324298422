import { FC, useMemo } from 'react';
import classnames from 'classnames/bind';

import { formatDate, titleCase } from '@/helpers';

import InfoButton from '@/components/common/InfoButton';

import styles from './StatusBadge.module.scss';

const cn = classnames.bind(styles);

type TStatusBadgeProps = {
  className?: string;
  currentStatus: TCurrentStatus;
};

const StatusBadge: FC<TStatusBadgeProps> = ({ className, currentStatus }) => {
  const { message, status, timestamp } = currentStatus;

  if (!status) {
    return null;
  }

  const badgeClassColorModifier = useMemo<string>(() => {
    const classNameMap: Record<TCurrentStatus['status'], string> = {
      CREATED: 'blue',
      STARTED: 'blue',
      ACTION_PROMPT: 'yellow',
      ACTION_CONFIRMED: 'green',
      ACTION_SKIPPED: 'yellow',
      PAUSED: 'yellow',
      RUNNING: 'blue',
      SUSPENDED: 'yellow',
      RESUMED: 'green',
      COMPLETE: 'green',
      CANCELLED: 'red',
      ERROR: 'red',
      WARNING: 'yellow',
    };

    return classNameMap[status];
  }, [status]);

  const tooltip = useMemo<string | undefined>(() => {
    if (timestamp) {
      return `${formatDate(timestamp, 'MMMM d, yyyy, p')} <br> <b>${message}</b>`;
    }

    return message;
  }, [message, timestamp]);

  return (
    <div className={cn('status-badge', `status-badge_${badgeClassColorModifier}`, className)}>
      {titleCase(status)}
      {tooltip && <InfoButton className={cn('status-badge__info-button')} tooltip={tooltip} />}
    </div>
  );
};

export default StatusBadge;
