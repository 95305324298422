import { v4 as uuidv4 } from 'uuid';
import Papa, { ParseResult } from 'papaparse';

import {
  barcodeFromServerSchema,
  cagingSegmentationFromServerSchema,
  cageEntityFromServerSchema,
  objectEntityFromServerSchema,
  sequencingCellReadsSchema,
  sequencingSummarySchema,
  TSequencingSummarySchema,
} from '@/validationSchemas';

import { parseCdnTable, getSnapshotId, parseContourXY } from '@/helpers/tables-parsers';
import { isCageContourFromServer, isCellContourFromServer } from '@/helpers/typeGuards';
import { getEntityFromServerFieldsForAxes } from '@/helpers/dimensions';
import { ECdnObjectType, TCdnObjectType } from '@/types/cdnData';

const CHUNK_SIZE_BYTES = 1024 * 1024 * 0.5;

export const parseIntWithoutNaN = (value: string) => {
  const parsedValue = parseInt(value, 10);
  return Number.isNaN(parsedValue) ? undefined : parsedValue;
};

export const parseFloatWithoutNaN = (value: string) => {
  const parsedValue = parseFloat(value);
  return Number.isNaN(parsedValue) ? undefined : parsedValue;
};

export function prepareChannelsObjectFields(objectFromServer: Record<string, string>, channelsFieldsNames: string[]) {
  const channelsObjectFields: Record<string, number | undefined> = {};
  channelsFieldsNames.forEach((channelFieldName) => {
    channelsObjectFields[channelFieldName] = parseFloatWithoutNaN(objectFromServer[channelFieldName]);
  });
  return channelsObjectFields;
}

export const getCageCropThumbnailUrl = (lane: TLane, channel: string, cageCropFileName: string) => {
  const base = `${process.env.REACT_APP_CDN_URL}/${lane.path}/`;
  const path = `${lane.cageCropsThumbnailsPath}/CROPS_${channel}/${cageCropFileName}_${channel}_60x60.png`;
  const url = new URL(path, base);
  return url.href;
};

export function prepareCropsPaths(lane: TLane, cageCropFileName: string) {
  return {
    cropsPaths: lane.channels.map(
      (channel: string) =>
        `${process.env.REACT_APP_CDN_URL}/${lane.cageCropsPath}/CROPS_${channel}/${cageCropFileName}_${channel}.png`
    ),
    cropsThumbnailPaths:
      (lane.cageCropsThumbnailsPath &&
        lane.channels.map((channel: string) => getCageCropThumbnailUrl(lane, channel, cageCropFileName))) ??
      ([] as string[]),
  };
}

export const transformSummaryTable = (response: string, lane: TLane): TObjectEntity[] => {
  const objectEntitiesFromServer = parseCdnTable({
    text: response,
    schema: objectEntityFromServerSchema,
    firstFieldInHeadersRow: 'object_id',
  });
  if (objectEntitiesFromServer.length === 0) {
    return [];
  }
  const isClassesFromPipelineV5 = Object.hasOwn(objectEntitiesFromServer[0], 'is_bead');
  return objectEntitiesFromServer.map((objectFromServer: Record<string, string>) => {
    const cageCropFileName = objectFromServer.cage_crop_file_name || objectFromServer.file_name;
    const isCell = parseIntWithoutNaN(objectFromServer.is_cell);
    const isBead = isClassesFromPipelineV5 ? parseIntWithoutNaN(objectFromServer.is_bead) : !isCell;
    return {
      is_cell: isCell,
      is_bead: isBead,
      cage_center_dist: parseFloatWithoutNaN(objectFromServer.cage_center_dist),
      cageId: parseIntWithoutNaN(objectFromServer.cage_id),
      objectId: parseIntWithoutNaN(objectFromServer.object_id),
      eccentricity: parseFloatWithoutNaN(objectFromServer.eccentricity),
      cageCropFileName,
      object_area_px: parseIntWithoutNaN(objectFromServer.object_area_px),
      yStage: parseIntWithoutNaN(objectFromServer.y_stage),
      xStage: parseIntWithoutNaN(objectFromServer.x_stage),
      perimeter_area_ratio: parseFloatWithoutNaN(objectFromServer.perimeter_area_ratio),
      snapshotId: parseIntWithoutNaN(objectFromServer.snapshot_id),
      globalCageIdMatched: parseIntWithoutNaN(objectFromServer.global_cage_id_matched),
      objBboxInCageXtl: parseFloat(objectFromServer.obj_bbox_in_cage_xtl),
      objBboxInCageXbr: parseFloat(objectFromServer.obj_bbox_in_cage_xbr),
      objBboxInCageYtl: parseFloat(objectFromServer.obj_bbox_in_cage_ytl),
      objBboxInCageYbr: parseFloat(objectFromServer.obj_bbox_in_cage_ybr),
      cageBboxXtl: parseFloat(objectFromServer.cage_bbox_xtl),
      cageBboxYtl: parseFloat(objectFromServer.cage_bbox_ytl),
      cageBboxXbr: parseFloat(objectFromServer.cage_bbox_xbr),
      cageBboxYbr: parseFloat(objectFromServer.cage_bbox_ybr),

      ...prepareChannelsObjectFields(objectFromServer, lane.channelsFieldsNames),

      // fields created on the frontend
      ...prepareCropsPaths(lane, cageCropFileName),
      uuid: uuidv4(),
    } as TObjectEntity;
  });
};

export const transformCageSummaryTable = (response: string, lane: TLane): TCageEntity[] => {
  const cageEntitiesFromServer = parseCdnTable({
    text: response,
    schema: cageEntityFromServerSchema,
    firstFieldInHeadersRow: 'file_name',
  });

  const fieldsForDimensions = getEntityFromServerFieldsForAxes(cageEntitiesFromServer[0]);
  if (cageEntitiesFromServer.length === 0) {
    return [];
  }
  return cageEntitiesFromServer.map((objectFromServer: Record<string, string>) => {
    const cageCropFileName = objectFromServer.cage_crop_file_name || objectFromServer.file_name;
    const cageEntity = {
      cageId: parseIntWithoutNaN(objectFromServer.cage_id),
      cageCropFileName,
      snapshotId: parseIntWithoutNaN(objectFromServer.snapshot_id),
      globalCageIdMatched: parseIntWithoutNaN(objectFromServer.global_cage_id_matched),
      yStage: parseIntWithoutNaN(objectFromServer.y_stage),
      xStage: parseIntWithoutNaN(objectFromServer.x_stage),

      // fields created on the frontend
      ...prepareCropsPaths(lane, cageCropFileName),
      uuid: uuidv4(),
    } as TCageEntity;

    // fields used in dimensions
    fieldsForDimensions.forEach((fieldName) => {
      cageEntity[fieldName] = parseFloatWithoutNaN(objectFromServer[fieldName]);
    });

    return cageEntity;
  });
};

export const transformCageContours = (response: string): TCageContour[] => {
  const cageContoursFromServer = parseCdnTable({
    text: response,
    typeGuard: isCageContourFromServer,
    columnSeparator: /\t/,
  }) as TCageContourFromServer[];
  if (cageContoursFromServer.length === 0) {
    return [];
  }
  const isPipelineV5 = Object.hasOwn(cageContoursFromServer[0], 'object_id');
  const objectIdFieldName = isPipelineV5 ? 'object_id' : 'cage_id';
  return cageContoursFromServer.map((cageContour) => ({
    imageId: cageContour.image_id,
    snapshotId: getSnapshotId(cageContour.image_id),
    objectId: parseIntWithoutNaN(cageContour[objectIdFieldName] ?? '') ?? 0,
    canvasPoints: parseContourXY(cageContour.contour_xy),
    globalCageIdMatched: parseIntWithoutNaN(cageContour.global_cage_id_matched),
  }));
};

export const transformCellContours = (response: string): TCellContour[] => {
  const cellContoursFromServer = parseCdnTable({
    text: response,
    typeGuard: isCellContourFromServer,
    columnSeparator: /\t/,
  }) as TCellContourFromServer[];
  if (cellContoursFromServer.length === 0) {
    return [];
  }
  const isPipelineV5 = Object.hasOwn(cellContoursFromServer[0], 'object_id');
  const objectIdFieldName = isPipelineV5 ? 'object_id' : 'cell_id';
  return cellContoursFromServer.map((cellContour) => ({
    imageId: cellContour.image_id,
    snapshotId: getSnapshotId(cellContour.image_id),
    objectId: parseIntWithoutNaN(cellContour[objectIdFieldName] ?? '') ?? 0,
    canvasPoints: parseContourXY(cellContour.contour_xy),
  }));
};

export const transformBarcodes = (response: string): TBarcodeCenter[] => {
  const objectListFromServer = parseCdnTable({
    text: response,
    schema: barcodeFromServerSchema,
    firstFieldInHeadersRow: 'barcode_name',
  });
  return objectListFromServer.map((objectFromServer: Record<string, string>) => ({
    barcodeName: objectFromServer.barcode_name,
    barcodeSequence: objectFromServer.barcode_sequence,
    x: parseInt(objectFromServer.x, 10),
    y: parseInt(objectFromServer.y, 10),
    r: parseInt(objectFromServer.r, 10),
  }));
};

export const transformCagingSegmentationCenters = (response: string): TCagingSegmentationCenter[] => {
  const objectListFromServer = parseCdnTable({
    text: response,
    schema: cagingSegmentationFromServerSchema,
    firstFieldInHeadersRow: 'image_id',
  });
  return objectListFromServer.map((objectFromServer: Record<string, string>) => ({
    x: parseInt(objectFromServer.x, 10),
    y: parseInt(objectFromServer.y, 10),
    r: parseInt(objectFromServer.r, 10),
  }));
};

export const transformCellReadsStatsResponse = (response: string): TCellReadStats[] => {
  const objectListFromServer = parseCdnTable({
    text: response,
    schema: sequencingCellReadsSchema,
    columnSeparator: /\t/,
  });
  return objectListFromServer.map((objectFromServer: Record<string, string>) => ({
    CB: objectFromServer.CB,
    cbMatch: parseInt(objectFromServer.cbMatch, 10),
    nUMIunique: parseInt(objectFromServer.nUMIunique, 10),
  }));
};

export const transformSequencingDataSummaryResponse = (response: string): TSequencingSummarySchema | undefined =>
  parseCdnTable({ text: response, schema: sequencingSummarySchema, isTranspose: true })[0];

export const transformWithPapaparse = (data: string, lane: TLane, type: TCdnObjectType) => {
  let result: TEntity[] = [];
  const handler = type === ECdnObjectType.objectEntity ? transformSummaryTableRows : transformCageSummaryTableRows;
  return new Promise((resolve) => {
    Papa.parse<TEntity>(data, {
      worker: true,
      chunkSize: CHUNK_SIZE_BYTES,
      header: true,
      skipEmptyLines: true,
      chunk: (chunk: ParseResult<any>) => {
        result = result.concat(handler(chunk.data, lane));
      },
      complete: () => {
        resolve({ data: result });
      },
    });
  });
};

export const transformSummaryTableRows = (objectEntitiesFromServer: any[], lane: TLane) => {
  const isClassesFromPipelineV5 = Object.hasOwn(objectEntitiesFromServer[0], 'is_bead');
  const transformedData = objectEntitiesFromServer.map((objectFromServer: Record<string, string>) => {
    const cageCropFileName = objectFromServer.cage_crop_file_name || objectFromServer.file_name;
    const isCell = parseIntWithoutNaN(objectFromServer.is_cell);
    const isBead = isClassesFromPipelineV5 ? parseIntWithoutNaN(objectFromServer.is_bead) : !isCell;
    return {
      is_cell: isCell,
      is_bead: isBead,
      cage_center_dist: parseFloatWithoutNaN(objectFromServer.cage_center_dist),
      cageId: parseIntWithoutNaN(objectFromServer.cage_id),
      objectId: parseIntWithoutNaN(objectFromServer.object_id),
      eccentricity: parseFloatWithoutNaN(objectFromServer.eccentricity),
      cageCropFileName,
      object_area_px: parseIntWithoutNaN(objectFromServer.object_area_px),
      yStage: parseIntWithoutNaN(objectFromServer.y_stage),
      xStage: parseIntWithoutNaN(objectFromServer.x_stage),
      perimeter_area_ratio: parseFloatWithoutNaN(objectFromServer.perimeter_area_ratio),
      snapshotId: parseIntWithoutNaN(objectFromServer.snapshot_id),
      globalCageIdMatched: parseIntWithoutNaN(objectFromServer.global_cage_id_matched),
      objBboxInCageXtl: parseFloat(objectFromServer.obj_bbox_in_cage_xtl),
      objBboxInCageXbr: parseFloat(objectFromServer.obj_bbox_in_cage_xbr),
      objBboxInCageYtl: parseFloat(objectFromServer.obj_bbox_in_cage_ytl),
      objBboxInCageYbr: parseFloat(objectFromServer.obj_bbox_in_cage_ybr),
      cageBboxXtl: parseFloat(objectFromServer.cage_bbox_xtl),
      cageBboxYtl: parseFloat(objectFromServer.cage_bbox_ytl),
      cageBboxXbr: parseFloat(objectFromServer.cage_bbox_xbr),
      cageBboxYbr: parseFloat(objectFromServer.cage_bbox_ybr),

      ...prepareChannelsObjectFields(objectFromServer, lane.channelsFieldsNames),

      // fields created on the frontend
      ...prepareCropsPaths(lane, cageCropFileName),
      uuid: uuidv4(),
    } as TObjectEntity;
  });
  return transformedData;
};

export const transformCageSummaryTableRows = (cageEntitiesFromServer: any[], lane: TLane): TCageEntity[] => {
  const fieldsForDimensions = getEntityFromServerFieldsForAxes(cageEntitiesFromServer[0]);
  if (cageEntitiesFromServer.length === 0) {
    return [];
  }
  return cageEntitiesFromServer.map((objectFromServer: Record<string, string>) => {
    const cageCropFileName = objectFromServer.cage_crop_file_name || objectFromServer.file_name;
    const cageEntity = {
      cageId: parseIntWithoutNaN(objectFromServer.cage_id),
      cageCropFileName,
      snapshotId: parseIntWithoutNaN(objectFromServer.snapshot_id),
      globalCageIdMatched: parseIntWithoutNaN(objectFromServer.global_cage_id_matched),
      yStage: parseIntWithoutNaN(objectFromServer.y_stage),
      xStage: parseIntWithoutNaN(objectFromServer.x_stage),

      // fields created on the frontend
      ...prepareCropsPaths(lane, cageCropFileName),
      uuid: uuidv4(),
    } as TCageEntity;

    // fields used in dimensions
    fieldsForDimensions.forEach((fieldName) => {
      cageEntity[fieldName] = parseFloatWithoutNaN(objectFromServer[fieldName]);
    });

    return cageEntity;
  });
};
