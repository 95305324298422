import { useState, useMemo, FC, useEffect, useRef, memo } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import classnames from 'classnames/bind';

import { themeOptions } from '@/types/theme';
import { EJobRunType } from '@/types/jobRuns';

import { addTooltip, NDASH } from '@/helpers';
import { getErrorMessage, showErrorToast } from '@/helpers/errors';
import { isReadsEmpty, isReadsHasChanges } from '@/helpers/sequencingData';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import useParamsExperimentId from '@/hooks/useParamsExperimentId';
import { useOpenModal } from '@/hooks/useOpenModal';
import useFindSucceededJobRuns from '@/hooks/jobRuns/useFindSucceededJobRuns';

import { appAPI } from '@/store/services/app';
import { experimentActions, experimentSelectors } from '@/store/slices/experiment';

import { useConfirmationModalContext } from '@/components/common/ConfirmationModalProvider';

import Select from '@/components/common/Select';
import Button from '@/components/common/Button';
import icons from '@/components/common/icons';

import KneePlotModal from './components/KneePlotModal';
import ReadsEdit from './components/ReadsEdit';
import SeuratGenerationProcessButton from './components/SeuratGenerationProcessButton';

import styles from './SequencingData.module.scss';
import GuideSeqSelect from './components/GuideSeqSelect';

const cn = classnames.bind(styles);

const FC_CONFIG_OPTION_LIST = [
  { label: 'FC_config_pfc_1440_combi_FSRB458_10UMI', value: 'FC_config_pfc_1440_combi_FSRB458_10UMI' },
  { label: 'FC_config_pfc_1440_combi_FSRB458_12UMI', value: 'FC_config_pfc_1440_combi_FSRB458_12UMI' },
  { label: 'FC_config_pfc_1440_combi_FSRB466and483_12UMI', value: 'FC_config_pfc_1440_combi_FSRB466and483_12UMI' },
  { label: 'FC_config_pfc_1440_combi_HD_12UMI', value: 'FC_config_pfc_1440_combi_HD_12UMI' },
  { label: 'FC_config_pfc_22800_combi_Av2_12UMI', value: 'FC_config_pfc_22800_combi_Av2_12UMI' },
  { label: 'FC_config_pfc_23040_combi_Av1_10UMI', value: 'FC_config_pfc_23040_combi_Av1_10UMI' },
  { label: 'FC_config_pfc_23040_combi_Av1_12UMI', value: 'FC_config_pfc_23040_combi_Av1_12UMI' },
  { label: 'FC_config_pfc_5760_combi_HD_12UMI', value: 'FC_config_pfc_5760_combi_HD_12UMI' },
  { label: 'FC_config_pfc_5760_combi_standard_10UMI', value: 'FC_config_pfc_5760_combi_standard_10UMI' },
  { label: 'FC_config_pfc_5760_combi_standard_12UMI', value: 'FC_config_pfc_5760_combi_standard_12UMI' },
  { label: 'FC_config_pfc_5760_combi_adherent', value: 'FC_config_pfc_5760_combi_adherent' },
  { label: 'FC_config_pfc_5760_combi_v2', value: 'FC_config_pfc_5760_combi_v2' },
  { label: 'FC_config_pfc_5760_FSRB946_10UMI_5P', value: 'FC_config_pfc_5760_FSRB946_10UMI_5P' },
];

type TSequencingDataProps = {
  laneDetails: TLaneDetails;
  sequencingData?: TSequencingData;
  handleGenomeSelect: (genome: string) => void;
  genomePresetValue?: string;
  isInEditMode?: boolean;
  selectedFcConfig: string;
  handleFcConfigChange: (fcConfig: string) => void;
  flowcellTypeFromServer?: string;
};

const SequencingData: FC<TSequencingDataProps> = ({
  laneDetails,
  sequencingData,
  handleGenomeSelect,
  genomePresetValue,
  isInEditMode = true,
  selectedFcConfig,
  handleFcConfigChange,
  flowcellTypeFromServer = '',
}) => {
  const appDispatch = useAppDispatch();
  const confirmationModal = useConfirmationModalContext();

  const { data: genomeOptionList = [] } = appAPI.useFetchGenomeOptionListQuery();

  const experimentId = useParamsExperimentId();
  const experimentData = useSelector(experimentSelectors.selectCurrentExperiment);
  const noSequencingLaneDataList = useSelector(experimentSelectors.selectNoSequencingLaneDataList);

  const { hasSucceededJobRun: hasSucceededStarSoloJobRun } = useFindSucceededJobRuns(
    EJobRunType.starSolo,
    experimentId,
    experimentData?.organizationId,
    laneDetails.id
  );

  const { isOpen: isKneePlotOpen, setIsOpen: setIsKneePlotOpen, onClose: onKneePlotClose } = useOpenModal();

  const selectContainerRef = useRef<Nullable<HTMLDivElement>>(null);

  const sequencingDataTooltip = useMemo(() => {
    if (!sequencingData) {
      return;
    }

    const { mRNA, guideSeq } = sequencingData;

    let mRNAInfo = `CC-mRNA-seq data <br/><br/> Genome: ${mRNA.genome ?? NDASH}<br/>Flowcell type: ${
      flowcellTypeFromServer ?? NDASH
    }`;
    if (mRNA.reads.length) {
      mRNA.reads.forEach((reads) => {
        mRNAInfo += `<br/><br/>Read1: ${reads.read1}<br/>Read2: ${reads.read2}`;
      });
    } else {
      mRNAInfo += `<br/>Reads: ${NDASH}`;
    }

    let guideSeqInfo = `<br/><br/> CC-CRISPR-seq data <br/><br/> CC-CRISPR-seq Reference: ${
      guideSeq?.baseDir?.trim() ? guideSeq.baseDir : NDASH
    }`;
    if (guideSeq.reads.length) {
      guideSeq.reads.forEach((reads) => {
        guideSeqInfo += `<br/><br/>Read1: ${reads.read1}<br/>Read2: ${reads.read2}`;
      });
    } else {
      guideSeqInfo += `<br/>Reads: ${NDASH}`;
    }

    return `${mRNAInfo} ${guideSeqInfo}`;
  }, [sequencingData, flowcellTypeFromServer]);

  const [selectedGenome, setSelectedGenome] = useState('');
  const [selectedMRnaReads, setSelectedMRnaReads] = useState<{ read1: string; read2: string }[]>([
    {
      read1: '',
      read2: '',
    },
  ]);

  const [selectedGuideSeqReference, setSelectedGuideSeqReference] = useState('');
  const [selectedGuideSeqReads, setSelectedGuideSeqReads] = useState<{ read1: string; read2: string }[]>([
    {
      read1: '',
      read2: '',
    },
  ]);

  const [
    updateSequencingDataByLanes,
    { isLoading: isUpdateInProgress, isSuccess: isUpdateSucceded, isError: isUpdateError, error: updateError },
  ] = appAPI.useUpdateSequencingDataByLanesMutation();

  const isDeleteOrUpdateInProgress = useMemo(() => isUpdateInProgress, [isUpdateInProgress]);

  const isMRNASavedDataEmpty = useMemo(() => {
    const { mRNA } = sequencingData ?? {};

    const { genome: mRNAGenome = '', reads: mRNAReads = [] } = mRNA ?? {};

    if (!mRNAGenome.length) return true;

    const isMRNAEmpty = isReadsEmpty(mRNAReads) && !mRNAGenome && !flowcellTypeFromServer;

    return isMRNAEmpty;
  }, [sequencingData, flowcellTypeFromServer]);

  const isGuideSeqSavedDataEmpty = useMemo(() => {
    const { guideSeq } = sequencingData ?? {};

    const { reads: guideSeqReads = [], baseDir } = guideSeq ?? {};

    if (!guideSeqReads.length || !selectedGuideSeqReference) return true;

    const isGuideSeqEmpty = isReadsEmpty(guideSeqReads) && !baseDir;

    return isGuideSeqEmpty;
  }, [sequencingData, selectedGuideSeqReference]);

  const isAllMRNAReadFieldsEmpty = useMemo(
    () => selectedMRnaReads.every((item) => !item.read1.trim() && !item.read2.trim()),
    [selectedMRnaReads]
  );

  const isAllGuideSeqReadFieldsEmpty = useMemo(
    () => selectedGuideSeqReads.every((item) => !item.read1.trim() && !item.read2.trim()),
    [selectedGuideSeqReads]
  );

  const isSequencingDataProcessed = useMemo(
    () =>
      !!(sequencingData?.mRNA?.genome && flowcellTypeFromServer && sequencingData?.mRNA?.reads.length > 0) &&
      !!(sequencingData?.guideSeq?.reads.length > 0 && sequencingData?.guideSeq?.baseDir?.trim()),
    [sequencingData, flowcellTypeFromServer]
  );

  const isMRNASequencingDataSaved = useMemo(() => {
    const { mRNA } = sequencingData ?? {};

    const { genome: mRNAGenome = '', reads: mRNAReads = [] } = mRNA ?? {};

    const isMRNAHasChanges =
      mRNAGenome !== selectedGenome ||
      flowcellTypeFromServer !== selectedFcConfig ||
      isReadsHasChanges(selectedMRnaReads, mRNAReads);

    return !isMRNAHasChanges;
  }, [sequencingData, selectedGenome, selectedFcConfig, selectedMRnaReads, flowcellTypeFromServer]);

  const isGuideSeqSequencingDataSaved = useMemo(() => {
    const { guideSeq } = sequencingData ?? {};

    const { reads: guideSeqReads = [], baseDir } = guideSeq ?? {};

    const isGuideSeqHasChanges = isReadsHasChanges(selectedGuideSeqReads, guideSeqReads);

    return !isGuideSeqHasChanges && selectedGuideSeqReference === baseDir;
  }, [sequencingData?.guideSeq?.reads, selectedGuideSeqReads, selectedGuideSeqReference]);

  const isSequencingDataSaved = useMemo(
    () => isMRNASequencingDataSaved && isGuideSeqSequencingDataSaved,
    [isMRNASequencingDataSaved, isGuideSeqSequencingDataSaved]
  );

  const isSaveSequencingDataDisabled = useMemo(() => {
    if (isDeleteOrUpdateInProgress) {
      return true;
    }

    const isAllMRNAFieldsFilledIn =
      selectedMRnaReads.every((item) => Boolean(item.read1.trim() && item.read2.trim())) &&
      !!selectedGenome &&
      !!selectedFcConfig;

    const isAllGuideSeqFilledIn =
      selectedGuideSeqReads.every((item) => Boolean(item.read1.trim() && item.read2.trim())) &&
      !!selectedGuideSeqReference;
    // isAllFieldsFilledIn - data will be saved
    // isAllReadFieldsEmpty - data will be deleted
    const isMRNAFEmpty = isAllMRNAFieldsFilledIn || (isAllMRNAReadFieldsEmpty && !isMRNASavedDataEmpty);
    const isGuideSeqEmpty = isAllGuideSeqFilledIn || (isAllGuideSeqReadFieldsEmpty && !isGuideSeqSavedDataEmpty);

    // if one of the sections is completely filled and ready to be saved, then the second section is expected to either have empty reads or be completely filled
    const isMRNAReadyToSave = isMRNAFEmpty && (isAllGuideSeqReadFieldsEmpty || isAllGuideSeqFilledIn);
    const isGuideSeqReadyToSave = isGuideSeqEmpty && (isAllMRNAReadFieldsEmpty || isAllMRNAFieldsFilledIn);

    const isSaveAllowed = isMRNAReadyToSave || isGuideSeqReadyToSave;

    return !isSaveAllowed;
  }, [
    isDeleteOrUpdateInProgress,
    selectedMRnaReads,
    selectedGenome,
    selectedFcConfig,
    isAllMRNAReadFieldsEmpty,
    isMRNASavedDataEmpty,
    isAllGuideSeqReadFieldsEmpty,
    isGuideSeqSavedDataEmpty,
    selectedGuideSeqReads,
    selectedGuideSeqReference,
  ]);

  const handleGenomeChange = (value: string) => {
    setSelectedGenome(value);
  };

  const handleGuideSeqReferenceChange = (value: string) => {
    setSelectedGuideSeqReference(value);
  };

  const processOtherLanesWithNoSequencingData = async ({
    flowcellType,
    newSequencingData,
  }: {
    flowcellType: string;
    newSequencingData: {
      mRNA: TMRnaSequencingDataFromServer;
      guideSeq?: TGuideSeqDataFromServer;
    };
  }) => {
    const otherEmptyLaneDataList = noSequencingLaneDataList.filter((laneData) => laneData.id !== laneDetails.id);
    if (!otherEmptyLaneDataList.length) {
      return;
    }
    const isConfirmed = await confirmationModal.onOpen({
      confirmationText: (
        <>
          Would you like to apply the same sequencing data configs to other lanes with no sequencing data (
          {otherEmptyLaneDataList.map((laneData) => laneData.name).join(', ')})?
        </>
      ),
      approveButtonText: 'Yes',
      cancelButtonText: 'No',
    });
    if (!isConfirmed) {
      return;
    }
    otherEmptyLaneDataList.forEach((laneData) => {
      updateSequencingDataByLanes({
        experimentId,
        flowcellType,
        sequencingData: [
          {
            ...newSequencingData,
            laneId: laneData.id,
          },
        ],
      });
    });
  };

  const handleProcessSequencingData = () => {
    let newMRnaSequencingData: TMRnaSequencingDataFromServer = {
      reads: [],
      genome: '',
    };

    if (isAllMRNAReadFieldsEmpty) {
      setSelectedGenome('');
      handleFcConfigChange('');
    } else {
      newMRnaSequencingData = {
        reads: selectedMRnaReads.map(({ read1, read2 }) => [read1, read2]),
        genome: selectedGenome,
      };
    }

    let newGuideSeqData: TGuideSeqDataFromServer = {
      reads: [],
      baseDir: '',
    };

    if (isAllGuideSeqReadFieldsEmpty) {
      setSelectedGuideSeqReference('');
    } else {
      newGuideSeqData = {
        reads: isAllGuideSeqReadFieldsEmpty ? [] : selectedGuideSeqReads.map(({ read1, read2 }) => [read1, read2]),
        baseDir: selectedGuideSeqReference,
      };
    }

    const newSequencingData = {
      mRNA: newMRnaSequencingData,
      guideSeq: newGuideSeqData,
    };

    updateSequencingDataByLanes({
      experimentId,
      flowcellType: isAllMRNAReadFieldsEmpty ? '' : selectedFcConfig,
      sequencingData: [
        {
          ...newSequencingData,
          laneId: laneDetails.id,
        },
      ],
    });
    processOtherLanesWithNoSequencingData({
      flowcellType: isAllMRNAReadFieldsEmpty ? '' : selectedFcConfig,
      newSequencingData: {
        mRNA: newMRnaSequencingData,
        guideSeq: newGuideSeqData,
      },
    });
  };

  const handleForceProcess = async() => {
    const isConfirmed = await confirmationModal.onOpen({
      confirmationText: 'This will reprocess everything which is a costly and time-taking operation, are you sure you want to continue?',
      approveButtonText: 'Yes',
      cancelButtonText: 'Cancel',
    });

    if (!isConfirmed) {
      return;
    }

    handleProcessSequencingData()
  }

  const openKneePlot = () => {
    setIsKneePlotOpen(true);
  };

  useEffect(() => {
    if (isUpdateError) {
      showErrorToast(getErrorMessage(updateError));
    }
  }, [isUpdateError, updateError]);

  useEffect(() => {
    if (isUpdateSucceded) {
      toast.success('Process has been started');
    }
  }, [isUpdateSucceded]);

  useEffect(() => {
    if (!sequencingData) {
      return;
    }

    const { mRNA, guideSeq } = sequencingData;

    if (mRNA.reads.length) {
      setSelectedMRnaReads(mRNA.reads);
    }

    if (guideSeq.reads.length) {
      setSelectedGuideSeqReads(guideSeq.reads);
    }

    setSelectedGenome(mRNA.genome);
    setSelectedGuideSeqReference(guideSeq.baseDir);

    handleFcConfigChange(flowcellTypeFromServer);
  }, [sequencingData, flowcellTypeFromServer]);

  useEffect(() => {
    appDispatch(
      experimentActions.setNoSequencingDataLaneId({
        laneData: { id: laneDetails.id, name: laneDetails.sampleFriendlyName },
        isProcessed: isSequencingDataProcessed,
      })
    );
  }, [laneDetails.id, isSequencingDataProcessed]);

  useEffect(() => {
    if (selectedGenome && selectedFcConfig) {
      handleGenomeSelect(selectedGenome);
    }
  }, [selectedGenome, selectedFcConfig]);

  useEffect(() => {
    if (genomePresetValue && !selectedGenome) {
      setSelectedGenome(genomePresetValue);
    }
  }, [genomePresetValue]);

  useEffect(() => {
    if (!selectContainerRef?.current) {
      return;
    }
    selectContainerRef?.current?.style?.setProperty(
      '--container-top-offset',
      `${selectContainerRef?.current?.offsetTop}px`
    );
  }, [selectContainerRef]);

  return (
    <div className={cn('sequencing-data')}>
      <div
        id="sequencing-data__control-container"
        className={cn('sequencing-data__control-container', {
          'sequencing-data__control-container_edit-mode': isInEditMode,
        })}
        ref={selectContainerRef}
      >
        {isInEditMode ? (
          <>
            <span className={cn('sequencing-data__column1')}>CC-mRNA-seq</span>
            <Select
              placeholder="Genome"
              options={genomeOptionList}
              value={selectedGenome}
              onChange={handleGenomeChange}
              className={cn('sequencing-data__selector', 'sequencing-data__column2')}
              controlClassName={cn('sequencing-data__selector-control')}
              menuClassName={cn('sequencing-data__selector-menu')}
              menuListClassName={cn('sequencing-data__selector-menu-list')}
              theme={themeOptions.light}
            />
            <Select
              placeholder="Flowcell config"
              options={FC_CONFIG_OPTION_LIST}
              value={selectedFcConfig}
              onChange={handleFcConfigChange}
              className={cn('sequencing-data__selector', 'sequencing-data__column3')}
              controlClassName={cn('sequencing-data__selector-control')}
              menuClassName={cn('sequencing-data__selector-menu')}
              menuListClassName={cn('sequencing-data__selector-menu-list')}
              theme={themeOptions.light}
            />
            <ReadsEdit selectedReads={selectedMRnaReads} setSelectedReads={setSelectedMRnaReads} />

            <span className={cn('sequencing-data__column1')}>CC-CRISPR-seq</span>
            <GuideSeqSelect
              selectedValue={selectedGuideSeqReference}
              setSelectedValue={handleGuideSeqReferenceChange}
              placeholder="CC-CRISPR-seq Reference"
              className={cn('sequencing-data__selector', 'sequencing-data__column2')}
            />
            <ReadsEdit selectedReads={selectedGuideSeqReads} setSelectedReads={setSelectedGuideSeqReads} />
          </>
        ) : (
          <>
            {sequencingData && (
              <div className={cn('sequencing-data__buttons-container')}>
                {sequencingDataTooltip && (
                  <div
                    {...addTooltip(sequencingDataTooltip)}
                    className={cn('sequencing-data__control-button', 'sequencing-data__control-button_info')}
                  >
                    i
                  </div>
                )}
                {isSequencingDataProcessed && hasSucceededStarSoloJobRun && (
                  <>
                    <button
                      {...addTooltip('Knee plot')}
                      className={cn('sequencing-data__control-button')}
                      onClick={openKneePlot}
                    >
                      <icons.ChartViewIcon />
                    </button>
                    <KneePlotModal laneId={laneDetails.id} isOpen={isKneePlotOpen} onRequestClose={onKneePlotClose} />
                  </>
                )}
              </div>
            )}
            {!sequencingData && NDASH}
          </>
        )}
      </div>

      {isInEditMode && (
        <div className={cn('sequencing-data__buttons', 'sequencing-data__buttons_process')}>
          {!isSequencingDataSaved && (
            <Button
              color="white"
              className={cn('sequencing-data__process-button')}
              onClick={handleProcessSequencingData}
              isLoading={isDeleteOrUpdateInProgress}
              disabled={isSaveSequencingDataDisabled}
            >
              Process
            </Button>
          )}
          <SeuratGenerationProcessButton
            laneDetails={laneDetails}
            sequencingDataGenome={sequencingData?.mRNA?.genome ?? ''}
            isSequencingDataSaved={isSequencingDataSaved}
          />
          <Button
              color="white"
              className={cn('sequencing-data__process-button', 'sequencing-data__process-button_force-process')}
              onClick={handleForceProcess}
              isLoading={isDeleteOrUpdateInProgress}
              tooltip='Force Reprocess'
              disabled={isSaveSequencingDataDisabled}
            >
              <icons.ExclamationIcon width={40} />
            </Button>
        </div>
      )}
    </div>
  );
};

export default memo(SequencingData);
